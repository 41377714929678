import moment from "moment";
export function display_date(value, strFormat) {
  // if(value && !value.includes('.000Z')) {
  //   value = value + '.000Z';
  // }
  return value ? moment(value).format(strFormat ? strFormat : 'DD-MM-YYYY HH:mm:ss') : '';
}

import config from '../configs'
export function order_status(value, type = 0){
  const objStatus = type == 0 ? config.ORDER_STATUS.find(obj => obj.value === value) : config.ORDER_DEPOSIT_STATUS.find(obj => obj.value === value);
  return objStatus ? objStatus.name : '';
}

export function order_status_color(value, type = 0){
  const objStatus = type == 0 ? config.ORDER_STATUS.find(obj => obj.value === value) : config.ORDER_DEPOSIT_STATUS.find(obj => obj.value === value);
  return objStatus ? objStatus.color : 'black';
}

export function order_status_text_color(value, type = 0){
  const objStatus = type == 0 ? config.ORDER_STATUS.find(obj => obj.value === value) : config.ORDER_DEPOSIT_STATUS.find(obj => obj.value === value);
  return objStatus ? objStatus.textColor : 'black';
}

export function vip_level(value){
  const objRet = config.VIP_LEVEL.find(obj => obj.value === value);
  return objRet ? objRet.name : '';
}

export function vip_level_color(value){
  const objRet = config.VIP_LEVEL.find(obj => obj.value === value);
  return objRet ? objRet.color : '';
}

export function capitalize(value){
  return value.toLowerCase().replace(/^.|\s\S/g, function(a) { return a.toUpperCase(); });
};

export function display_value(value, enums, key_name = 'value', val_name = 'name') {
  if(!enums) {
    return '';
  }
  const item = enums.find(obj => obj[key_name] === value);
  return item && item[val_name] ? item[val_name] : '';
}

export function display_color(value, enums) {
  const item = enums.find(obj => obj.value === value);
  return item ? item.color : '';
}

export function display_text_color(value, enums) {
  const item = enums.find(obj => obj.value === value);
  return item ? item.textColor : '';
}