import Vue from "vue";
import App from "./App.vue";
import router from "./router/";
import store from "./store/";
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'font-awesome/css/font-awesome.css'
// import './assets/css/main.css'
import './assets/css/deposit.css'
import { currency } from './commons/currency'
import { display_date, order_status, order_status_color, order_status_text_color, vip_level, vip_level_color, capitalize, display_value, display_color, display_text_color } from './commons/formater'
import VueResource from 'vue-resource'
import vuetify from './plugins/vuetify'
import VueMuuriGrid from 'vue-muuri-grid'
import HighchartsVue from 'highcharts-vue'
import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyDkMElEWZmvaPVQmhx1ZpBrMaz0ozRWFrw",
  authDomain: "viettaobao-oee.firebaseapp.com",
  projectId: "viettaobao-oee",
  storageBucket: "viettaobao-oee.appspot.com",
  messagingSenderId: "416168122677",
  appId: "1:416168122677:web:01b8433b52164f9a10147c",
  measurementId: "G-KMS42MMX9Q"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// Vue.use(VueQuagga);
Vue.use(VueResource);
Vue.use(require('vue-shortkey'))
Vue.use(VueMuuriGrid);
Vue.use(HighchartsVue);
Vue.filter('currency', currency)
Vue.filter('display_date', display_date)
Vue.filter('order_status', order_status)
Vue.filter('order_status_color', order_status_color)
Vue.filter('order_status_text_color', order_status_text_color)
Vue.filter('vip_level', vip_level)
Vue.filter('vip_level_color', vip_level_color)
Vue.filter('capitalize', capitalize)
Vue.filter('display_value', display_value)
Vue.filter('display_color', display_color)
Vue.filter('display_text_color', display_text_color)

import currencyInput from './components/general/CurrencyInput';
Vue.component('h-currency-input', currencyInput);
import autoCompletedInput from './components/general/AutoCompletedInput';
Vue.component('h-autocomplete-input', autoCompletedInput);
import tableHeaderFilter from './components/widgets/TableHeaderFilter';
Vue.component('table-header-filter', tableHeaderFilter);
import widgetTemp from '@/components/dashboard/WidgetTemp';
Vue.component('widget-temp', widgetTemp);
Vue.config.productionTip = false;

/* Syntax: array.insert(index, value1, value2, ..., valueN) */
Array.prototype.insert = function(index) {
  this.splice.apply(this, [index, 0].concat(Array.prototype.slice.call(arguments, 1)));
  return this;
};

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
