import Crud from './crud'
var crud = new Crud('feeweight', 'phí cân nặng');

const state = {
    ...crud.state,
    selected: {
        ID: 0,
        ChinaStoreID: 1,
        ReceivePlaceID: 1,
        WeightFrom: 0,
        WeightTo: 0,
        Amount: 0
    }
};
const getters = {
    ...crud.getters,
};
const actions = {
    ...crud.actions,
};
const mutations = {
    ...crud.mutations,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};