import { request_list} from '@/commons/hpod';
import Vue from 'vue';
const endpoint = 'role'
// initial state
const state = {
    loading: false,
    all: {}
};

// getters
const getters = {};

// actions
const actions = {
    getList({ commit }, param) {
        request_list(endpoint, data => {
            let user = JSON.parse(localStorage.getItem('user'))
            let role = _.reduce(_.filter(data.data, function(obj) {
                return (obj.RoleType & user.roletype) == obj.RoleType;  
            }), function(result, obj) {
                result.Module = result.Module | obj.Module;
                result.Report = result.Report | obj.Report;
                result.Orders = result.Orders | obj.Orders;
                result.BigPackage = result.BigPackage | obj.BigPackage;
                result.OrderEditable = result.OrderEditable | obj.OrderEditable;
                result.Customer = result.Customer | obj.Customer;
                result.TransactionCode = result.TransactionCode | obj.TransactionCode;
                result.StoreCN = result.StoreCN | obj.StoreCN;
                return result;
            }, {Module: 0, Report: 0, Orders: 0, BigPackage: 0, OrderEditable: 0, Customer: 0, TransactionCode: 0, StoreCN: 0})
            localStorage.setItem('user_role', JSON.stringify(role));
            commit('authentication/setRole', role, {root: true});
            commit('setList', data);
        }, Vue.http, param, this.state.authentication.token);
    }
};

// mutations
const mutations = {
    setList(state, data) {
        state.all = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
