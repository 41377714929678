import { request_detail, request_list, update_detail, create_detail, remove_detail } from '@/commons/hpod';
import { sendSuccessNotice, sendErrorNotice } from '@/commons/utils';
import Vue from 'vue';
const endpoint = 'storereport'
const viewTitle = 'báo cáo thu chi'

// initial state
const state = {
    loading: false,
    all: {},
    selected: {
        ID: 0,
        UID: 0,
        Username: ''
    }
};

// getters
const getters = {};

// actions
const actions = {
    getList({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list(endpoint, data => {
            commit('setList', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        request_detail(endpoint, data => {
            commit('setDetail', data);
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    setDetail({ dispatch }, {param, data, id}) {
        if(id > 0) {
            update_detail(endpoint, res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice(`Không thể cập nhật ${viewTitle}.`);
                }
                else {
                    sendSuccessNotice(`Cập nhật ${viewTitle} thành công.`);
                    if(param) {
                        dispatch('getList', param);
                    }
                    else {
                        dispatch('getDetail', id);
                    }
                }                
            }, Vue.http, data, id, this.state.authentication.token);
        }
        else {
            delete data.ID;
            create_detail(endpoint, res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice(`Không thể thêm mới ${viewTitle}.`)
                }
                else {
                    sendSuccessNotice(`Thêm mới ${viewTitle} thành công.`);
                }
            }, Vue.http, data, this.state.authentication.token);
        }
    },
    removeDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        remove_detail(endpoint, res => {
            if (res.data.status === false) {
                sendErrorNotice(`Không thể xóa ${viewTitle}.`)
            }
            else {
                sendSuccessNotice(`Đã xóa ${viewTitle} thành công.`)
            }
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
};

// mutations
const mutations = {
    setList(state, data) {
        state.all = data;
    },
    setDetail(state, item) {
        state.selected = item.data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
