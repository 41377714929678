import { request_detail, request_list, update_detail, create_detail } from '@/commons/hpod';
import { sendSuccessNotice, sendErrorNotice, refreshResource } from '@/commons/utils';
import Vue from 'vue';
import configs from '@/configs'
const endpoint = 'recharge_histories'

// initial state
const state = {
    loading: false,
    all: {
        data: [],
        total: 0,
        summary: {}
    },
    selected: {
        ID: 0,
        UID: 0,
        Method: 4,
        Username: '',
        Amount: 0,
        Status: configs.RECHARGE_STATUS.Pending,
        TradeContent: ''
    }
};

// getters
const getters = {};

// actions
const actions = {
    getList({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list(endpoint, data => {
            commit('setRechargeHistoryList', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        request_detail(endpoint, data => {
            commit('setDetail', data);
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    setDetail({ commit }, {data, id, cancel}) {
        commit("setLoading", { loading: true });
        if(id > 0) {
            // delete data.CreatedDate;
            delete data.CreatedBy;
            // data.ModifiedDate = getCurrentTime().format(configs.SQL_DATE_FORMAT);
            data.ModifiedBy = this.state.authentication.user.username;
            update_detail(endpoint, res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice('Không thể cập nhật lệnh nạp tiền.')
                }
                else if(cancel) {
                    sendSuccessNotice('Hủy lệnh nạp tiền thành công.')
                    refreshResource();
                }
                else {
                    sendSuccessNotice('Cập nhật lệnh nạp tiền thành công.')
                    refreshResource();
                }
                commit("setLoading", { loading: false });
            }, Vue.http, data, id, this.state.authentication.token);
        }
        else {
            data.CreatedBy = this.state.authentication.user.username;
            delete data.ID;
            create_detail(endpoint, res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice('Không thể thêm mới lệnh nạp tiền.')
                }
                else {
                    sendSuccessNotice('Thêm mới lệnh nạp tiền thành công.')
                    refreshResource();
                }
                commit("setLoading", { loading: false });
            }, Vue.http, data, this.state.authentication.token);
        }
    },
};

// mutations
const mutations = {
    setRechargeHistoryList(state, data) {
        state.all = data;
    },
    setDetail(state, item) {
        state.selected = item.data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
