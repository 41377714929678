import { sendSuccessNotice, sendErrorNotice, getCurrentTime } from '@/commons/utils';
import { request_detail, request_list, update_detail, create_detail, remove_detail } from '@/commons/hpod';
import Vue from 'vue';
const endpoint = 'customer_levels'
import configs from '@/configs'
import moment from "moment"
// initial state
const state = {
    loading: false,
    all: {},
    selected: {
        ID: 0,
        VIPName: '',
        VIPContent: '',
        FromMoney: 0,
        ToMoney: 0,
        BuyDiscount: 0,
        WeightDiscount: 0,
        DepositMin: 0,
        FreeShipNT: 0,
        GiftTet: 0,
        GiftHBD: 0,
        HolidayHPO: 0
    }
};

// getters
const getters = {};

// actions
const actions = {
    getCustomerLevelList({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list(endpoint, data => {
            commit('setCustomerLevelList', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getCustomerLevelDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        request_detail(endpoint, data => {
            commit('setCustomerLevelDetail', data);
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    removeCustomerLevelDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        remove_detail(endpoint, res => {
            if (res.data.status === false) {
                sendErrorNotice('Không thể xóa level.')
            }
            else {
                sendSuccessNotice('Đã xóa level thành công.')
            }
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    setCustomerLevelDetail({ commit }, {data, id}) {
        commit("setLoading", { loading: true });
        if(id > 0) {
            delete data.CreatedDate;
            update_detail(endpoint, res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice('Không thể cập nhật level.')
                }
                else {
                    sendSuccessNotice('Cập nhật level thành công.')
                }
                commit("setLoading", { loading: false });
            }, Vue.http, data, id, this.state.authentication.token);
        }
        else {
            // data.CreatedDate = getCurrentTime().format(configs.SQL_DATE_FORMAT);
            data.FromMoney = data.FromMoney || 0;
            data.BuyDiscount = data.BuyDiscount || 0;
            data.WeightDiscount = data.WeightDiscount || 0;
            delete data.ID;
            create_detail(endpoint, res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice('Không thể thêm mới level.')
                }
                else {
                    sendSuccessNotice('Thêm mới level thành công.')
                }
                commit("setLoading", { loading: false });
            }, Vue.http, data, this.state.authentication.token);
        }
    },
};

// mutations
const mutations = {
    setCustomerLevelList(state, data) {
        state.all = data;
    },
    setCustomerLevelDetail(state, item) {
        state.selected = item.data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
