export default {
  SYSTEM_TYPE: "deposit",
  // API: "http://127.0.0.1:9332",
  // API_DEPOSIT: "http://127.0.0.1:9332",
  API: "https://teev4-api.phanmemdathangoee.com",
  API_DEPOSIT: "https://teev4-api.phanmemdathangoee.com",
  OFFER_API: "http://partner.alibo.vn/customer_commission_api",
  OFFER_TOKEN: "e6f838758c782529a9dac07561b1b2bc",
  SHORT_DATE_FORMAT: "DD-MM-YYYY",
  DATE_FORMAT: "DD/MM/YYYY HH:mm:ss",
  SQL_DATE_FORMAT: "YYYY-MM-DD HH:mm:ss",
  SYSTEM_CONFIG: {
    domain: 'https://phanmemdathangoee.com',
    firebase_storage: 'https://firebasestorage.googleapis.com/v0/b/viettaobao-oee.appspot.com/o',
    bucketName: 'viettaobao-oee.appspot.com',
    quick_store_import: false,
    notification_time: 300,
    auto_pack_code: false,
    accountant_order_fee_column: false
  },
  PAGINATION: {
    page: 1,
    itemsPerPage: 20,
    mustSort: true,
    sortDesc: [1],
    sortBy: ["ID"]
  },
  ITEMS_PER_PAGE:{
    'items-per-page-options': [20, 30, 40, 50, 100, 200, 500]
  },
  
  ORDER_TYPE: {
    order: 1,
    deposit: 2
  },
  ORDER_TYPE_LIST: [
    { value: 1, name: 'Mua hộ' },   
    { value: 2, name: 'Ký gửi' }   
  ],
  ORDER_STATUS_ENUM: {
    cho_dat_coc: 0,
    huy: 1,
    da_dat_coc: 2,
    cho_bao_gia: 3,
    dang_dat_hang: 4,
    da_mua_hang: 5,
    dang_van_chuyen: 16,
    da_nhap_kho_tq: 11,
    khieu_nai_shop: 14,
    van_chuyen_ve_vn: 12,
    da_nhap_kho_vn: 7,
    da_nhap_kho_phat: 8,
    da_xuat_kho: 9,
    khieu_nai: 13,
    hoan_thanh:10
  },
  ORDER_STATUS: [
    { value: 1, name: 'Hủy đơn hàng', disabled: false, color: '#000000', textColor: '#ffffff' },
    { value: 3, name: 'Chờ báo giá', disabled: false, color: '#9e5959', textColor: '#ffffff' },    
    { value: 0, name: 'Chờ đặt cọc', disabled: false, color: '#DF0101', textColor: '#ffffff' },
    { value: 2, name: 'Đã đặt cọc', disabled: false, color: '#0B610B', textColor: '#ffffff' },
    { value: 4, name: 'Đang đặt hàng', disabled: false, color: '#0489B1', textColor: '#ffffff' },
    { value: 5, name: 'Đã mua hàng', disabled: false, color: '#0489B1', textColor: '#ffffff' },
    { value: 16, name: 'Shop phát hàng', disabled: false, color: '#F7819F', textColor: '#ffffff' },
    { value: 11, name: 'Đã nhập kho TQ', disabled: false, color: '#FE9A2E', textColor: '#ffffff' },
    { value: 14, name: 'Khiếu nại shop', disabled: false, color: '#F7819F', textColor: '#ffffff' },
    { value: 12, name: 'Vận chuyển về VN', disabled: false, color: '#FE9A2E', textColor: '#ffffff' },
    { value: 7, name: 'Đã nhập kho VN', disabled: false, color: '#FE9A2E', textColor: '#ffffff' },
    { value: 8, name: 'Nhập kho phát', disabled: false, color: '#FE9A2E', textColor: '#ffffff' },
    { value: 9, name: 'Đã xuất kho', disabled: false, color: '#0000FF', textColor: '#ffffff' },
    { value: 13, name: 'Khiếu nại', disabled: false, color: '#D358F7', textColor: '#ffffff' },
    { value: 10, name: 'Hoàn thành', disabled: false, color: '#A4A4A4', textColor: '#ffffff' },
  ],
  ORDER_DEPOSIT_STATUS_ENUM: {
    dang_van_chuyen: 16,
    da_nhap_kho_tq: 11,
    van_chuyen_ve_vn: 12,
    da_nhap_kho_vn: 7,
    cho_thanh_toan: 8,
    da_thanh_toan: 9,
    hoan_thanh: 10,
    huy_don_hang: 1
  },
  ORDER_DEPOSIT_STATUS: [
    { value: 1, name: 'Hủy đơn hàng', color: '#000000', textColor: '#ffffff' },
    { value: 16, name: 'Shop phát hàng', color: '#F7819F', textColor: '#ffffff' },
    { value: 11, name: 'Đã nhập kho TQ', color: '#FE9A2E', textColor: '#ffffff' },
    { value: 12, name: 'Vận chuyển về VN', color: '#FE9A2E', textColor: '#ffffff' },
    { value: 7, name: 'Đã nhập kho VN', color: '#FE9A2E', textColor: '#ffffff' },
    { value: 8, name: 'Chờ thanh toán', color: '#FACC2E', textColor: '#ffffff' },
    { value: 9, name: 'Đã xuất kho', color: '#0000FF', textColor: '#ffffff' },
    { value: 10, name: 'Hoàn thành', color: '#A4A4A4', textColor: '#ffffff' },
  ],
  PAY_SERVICE_TYPE_ENUM: {
    chuyen_khoan_ho: 0,
    thanh_toan_ho: 1
  },
  PAY_SERVICE_TYPE: [
    { value: 0, name: 'Chuyển khoản hộ', color: '#000000', textColor: '#ffffff' },
    { value: 1, name: 'Ủy quyền Alipay', color: '#DF0101', textColor: '#ffffff' }
  ],
  PAY_SERVICE_STATUS_ENUM: {
    don_huy: 1,
    cho_bao_gia: 2,
    cho_thanh_toan: 3,
    da_thanh_toan: 4,
    hoan_thanh: 5
  },
  PAY_SERVICE_STATUS: [
    { value: 1, name: 'Đơn huỷ', color: '#000000', textColor: '#ffffff' },
    { value: 2, name: 'Chờ báo giá', color: '#F7819F', textColor: '#ffffff' },
    { value: 3, name: 'Chờ thanh toán', color: '#FACC2E', textColor: '#ffffff' },
    { value: 4, name: 'Đã thanh toán', color: '#FE9A2E', textColor: '#ffffff' },
    { value: 5, name: 'Hoàn thành', color: '#0000FF', textColor: '#ffffff' }
  ],
  VIP_LEVEL: [
    { value: 1, name: '', color: '' },
    { value: 2, name: 'v1', color: '#FFCC80' },
    { value: 3, name: 'v2', color: '#FFB74D' },
    { value: 4, name: 'v3', color: '#FFA726' },
    { value: 5, name: 'v4', color: '#FB8C00' },
    { value: 6, name: 'v5', color: '#F57C00' },
    { value: 7, name: 'v6', color: '#EF6C00' },
  ],
  PACKAGE_STATUS_ENUM: {
    CHUA_PHAT_HANG: 0,
    DANG_DI_CHUYEN: 1,
    DA_VE_TQ: 2,
    DANG_VE_VN: 3,
    DA_VE_VN: 4,
    DA_XUAT_VN: 5,
    DA_NHAP_KHO_PHAT: 6,
    KH_DA_NHAN: 7
  },
  PACKAGE_STATUS: [
    { value: 0, name: 'Chưa phát hàng', disabled: false, color: 'red', textColor: '#000' },
    { value: 1, name: 'Đang vận chuyển', disabled: false, color: 'black', textColor: '#FFF' },
    { value: 2, name: 'Đã về kho TQ', disabled: false, color: 'green', textColor: '#FFF' },
    { value: 3, name: 'Đang chuyển về VN', disabled: false, color: 'green', textColor: '#FFF' },
    { value: 4, name: 'Đã về kho VN', disabled: false, color: 'green', textColor: '#FFF' },
    { value: 5, name: 'Đã xuất kho VN', disabled: false, color: 'black', textColor: '#FFF' },
    // { value: 6, name: 'Đã nhập kho phát', disabled: false, color: 'black', textColor: '#FFF' },
    // { value: 7, name: 'Đã xuất kho phát', disabled: false, color: 'blue', textColor: '#FFF' },
    // { value: 8, name: 'Đã giao hàng', disabled: false, color: 'blue', textColor: '#FFF' },
  ],
  BIG_PACKAGE_STATUS_ENUM: {
    DANG_DONG_BAO: 0,
    XUAT_KHO_TQ: 1,
    NHAP_KHO_VN: 2
  },
  BIG_PACKAGE_STATUS: [
    { value: 0, name: 'Đang đóng bao', disabled: false, color: 'red', textColor: '#000' },
    { value: 1, name: 'Xuất kho TQ', disabled: false, color: 'black', textColor: '#FFF' },
    { value: 2, name: 'Nhập kho VN', disabled: false, color: 'green', textColor: '#FFF' }
  ],
  COMMENT_TYPE: [
    { value: 1, name: 'Công khai', color: '#2EBB32' },
    { value: 2, name: 'Nội bộ', color: '#dc3545' }
  ],
  CUSTOMER_STATUS: {
    InActive: 1,
    Actived: 2,
    Blocked: 3
  },
  CUSTOMER_STATUS_LIST: [
    { value: 1, name: 'Chưa kích hoạt', color: '#808080' },
    { value: 2, name: 'Đã kích hoạt', color: '#0059b3' },
    { value: 3, name: 'Đang bị khóa', color: '#e63900' },
  ],
  RECHARGE_STATUS: {
    Pending: 1,
    Approved: 2,
    Cancel: 3,
    Transferred: 4,
    Cash: 5,
  },
  RECHARGE_STATUS_LIST: [
    { value: 1, name: 'Chờ duyệt', color: '#808080', textColor: '#000' },
    { value: 2, name: 'Đã duyệt', color: '#0059b3', textColor: '#FFF' },
    { value: 3, name: 'Hủy', color: '#e63900', textColor: '#000' },
    // { value: 4, name: 'Chuyển khoản', color: '#00b300', textColor: '#FFF' },
    // { value: 5, name: 'Tiền mặt', color: '#2d8659', textColor: '#FFF' },
  ],
  WITHDRAW_STATUS: {
    Pending: 1,
    Approved: 2,
    Cancel: 3,
  },
  WITHDRAW_STATUS_LIST: [
    { value: 1, name: 'Chờ duyệt', color: '#dc3545', textColor: '#000' },
    { value: 2, name: 'Đã duyệt', color: '#0059b3', textColor: '#FFF' },
    { value: 3, name: 'Hủy lệnh', color: '#e63900', textColor: '#000' }
  ],
  PAYMENT_TYPE: {
    Deposit: 1,
    Complete: 2,
  },
  PAYMENT_TYPE_LIST: [
    { value: 1, name: 'Đặt cọc', color: '#2d8659', textColor: '#FFF' },
    { value: 2, name: 'Thanh toán', color: '#0059b3', textColor: '#FFF' },
  ],
  PAYMENT_STATUS: {
    DatCoc: 2,
    ThanhToan: 9,
    PhiShip: 10,
    PhiKhac: 11,
    NhanLaiCoc: 12
  },
  PAYMENT_STATUS_LIST: [
    { value: 2, name: 'Đặt cọc', color: '#2d8659', textColor: '#FFF' },
    { value: 9, name: 'Thanh toán', color: '#0059b3', textColor: '#FFF' },
    { value: 10, name: 'Phí ship', color: '#808080', textColor: '#FFF' },
    { value: 11, name: 'Phí khác', color: '#808080', textColor: '#FFF' },
    { value: 12, name: 'Nhận lại cọc', color: '#FFB74D', textColor: '#000' },
  ],
  COMPLAIN_STATUS: {
    Cancel: 0,
    Processing: 2,
    Pending: 1,    
    Done: 3,
  },
  COMPLAIN_STATUS_LIST: [
    { value: 0, name: 'Hủy', color: 'orange' },
    { value: 2, name: 'Đang xử lý', color: 'green' },
    { value: 1, name: 'Chờ xử lý', color: 'red' },    
    { value: 3, name: 'Đã xử lý', color: 'blue' }
  ],
  DATA_TYPE: {
    String: 0,
    Number: 1,
    Enum: 2,
    Date: 3
  },
  LIST_COMPARISON: [
    { name: 'Có chứa', value: 'contain', display: '({0})', dataType: 0 },
    { name: 'Không chứa', value: 'does_not_contain', display: '-({0})', dataType: 0 },
    { name: 'Bằng', value: 's_equal', display: '= {0}', dataType: 0 },
    { name: 'Khác', value: 's_does_not_equal', display: '!= {0}', dataType: 0 },
    { name: 'Bằng', value: 'equal', display: '= {0}', dataType: 1 },
    { name: 'Khác', value: 'does_not_equal', display: '!= {0}', dataType: 1 },
    { name: 'Lớn hơn', value: 'greater_than', display: '> {0}', dataType: 1 },
    { name: 'Lớn hơn hoặc bằng', value: 'greater_than_or_equal', display: '>= {0}', dataType: 1 },
    { name: 'Nhỏ hơn', value: 'less_than', display: '< {0}', dataType: 1 },
    { name: 'Nhỏ hơn hoặc bằng', value: 'less_than_or_equal', display: '<= {0}', dataType: 1 },
    { name: 'Bằng', value: 'in', display: '= [{0}]', dataType: 2 },
    { name: 'Khác', value: 'not_in', display: '!= [{0}]', dataType: 2 },
    { name: 'Bằng', value: 'd_equal', display: '= {0}', dataType: 3 },
    { name: 'Khác', value: 'd_does_not_equal', display: '!= {0}', dataType: 3 },
    { name: 'Lớn hơn', value: 'd_greater_than', display: '> {0}', dataType: 3 },
    { name: 'Lớn hơn hoặc bằng', value: 'd_greater_than_or_equal', display: '>= {0}', dataType: 3 },
    { name: 'Nhỏ hơn', value: 'd_less_than', display: '< {0}', dataType: 3 },
    { name: 'Nhỏ hơn hoặc bằng', value: 'd_less_than_or_equal', display: '<= {0}', dataType: 3 },
  ],
  ROLE_TYPE: {
    Admin: 1,
    Manager: 2,
    Purchase: 4,
    CustomerService: 8,
    InteralControl: 16,
    StoreVN: 32,
    StoreTQ: 64,
    Payment: 128,
  },
  ROLES: [
    { value: 1, name: 'Admin'},
    // { value: 2, name: 'Quản lý'},
    { value: 4, name: 'Mua hàng'},
    { value: 8, name: 'Chăm sóc khách hàng'},
    // { value: 16, name: 'Interal Control'},
    { value: 32, name: 'Kho Việt Nam'},
    { value: 64, name: 'Kho Trung Quốc'},
    { value: 128, name: 'Kế toán'},
  ],
  TRADE_TYPE: [
    { value: 1, name: 'Đặt cọc'},
    { value: 2, name: 'Nhận lại tiền đặt cọc' },
    { value: 3, name: 'Thanh toán hóa đơn' },
    { value: 4, name: 'Nạp tiền' },
    { value: 5, name: 'Rút tiền' },
    { value: 6, name: 'Hủy lệnh rút tiền' },
    { value: 7, name: 'Xử lý khiếu nại' },
    { value: 8, name: 'Hủy lệnh chuyển tiền' },
  ],
  SIGNAL_TYPE: [
    { value: 0, name: 'Chưa ký nhận'},
    { value: 1, name: 'Đã ký nhận' },
    { value: 3, name: 'Kiểm hàng' },
    { value: 2, name: 'Khác' }
    
  ],
  RECHARGE_TYPE: {
    Vietinbank: 1,
    Vietcombank: 2,
    BIDV: 3,
    AGRIBANK: 4,
    Techcombank: 5,
    MBBank: 6,
    Cash: 90,
    COD: 91,
    Yuan: 92,
  },
  RECHARGE_METHODS: [
    // { value: 1, name: 'Vietinbank'},
    { value: 2, name: 'Vietcombank'},
    // { value: 3, name: 'BIDV'},
    // { value: 4, name: 'AGRIBANK'},
    { value: 5, name: 'Techcombank'},
    // { value: 6, name: 'MBBank'},
    { value: 90, name: 'Tiền mặt'},
    { value: 91, name: 'COD'}
  ],
  MONEY_SOURCES: [
    // { value: 1, name: 'Vietinbank' },
    { value: 2, name: 'Vietcombank' },
    // { value: 3, name: 'BIDV' },
    // { value: 4, name: 'AGRIBANK' },
    { value: 5, name: 'Techcombank'},
    // { value: 6, name: 'MBBank'},
    { value: 90, name: 'Tiền mặt' },
    { value: 91, name: 'COD' },
    { value: 92, name: 'Yuan' },
    { value: 93, name: 'System' },
    { value: 99, name: 'Khác'}
    
  ],
  MONEY_COMMANDS: [
    
    { value: 1, name: 'Nạp tệ', type: 0, pay: 1 },
    { value: 2, name: 'Thu khác', type: 0, pay: 1 },
    { value: 3, name: 'Shop hoàn KN', type: 0, pay: 1 },
    { value: 4, name: 'Chi mua tệ', type: 1, pay: 1 },
    { value: 5, name: 'Chi phí nhân công', type: 1, pay: 1 },
    { value: 6, name: 'Thanh toán đối tác VCQT', type: 1, pay: 1 },
    { value: 7, name: 'Thanh toán đối tác VCVN', type: 1, pay: 1},
    { value: 8, name: 'Chi phí Marketing', type: 1, pay: 1 },
    { value: 9, name: 'Chi phí Quản lý', type: 1, pay: 1},
    { value: 10, name: 'Chi phí bán hàng', type: 1, pay: 1 },
    { value: 11, name: 'Chi phí khác', type: 1, pay: 1 },
    { value: 12, name: 'Chi phí mua hàng', type: 1, pay: 1 },
    { value: 90, name: 'Chuyển tiền nội bộ', type: -1 },
    { value: 91, name: 'Khách nạp ví', type: 0},
    { value: 92, name: 'Khách rút tiền', type: 1 },
    { value: 93, name: 'Hủy nạp ví', type: 1},
    { value: 94, name: 'Hủy lệnh rút tiền', type: 0},
  ],
  PAYMENTORDER_STATUS: {
    Unpaid: 0,
    Paid: 1,
  },
  PAYMENTORDER_STATUS_LIST: [
    { value: 0, name: 'Chưa thanh toán', color: '#e63900', textColor: '#FFF' },
    { value: 1, name: 'Đã thanh toán', color: '#0059b3', textColor: '#FFF' },
  ],
  TUIKUAN_STATUS: {
    cho_xu_ly: 0,
    da_xu_ly: 1,
  },
  TUIKUAN_STATUS_LIST: [
    { value: 0, name: 'Chờ xử lý', color: '#e63900', textColor: '#FFF' },
    { value: 1, name: 'Đã xử lý (Tăng TKKH+PT)', color: '#0059b3', textColor: '#FFF' },
  ],
  PRICES_STATUS: {
    chua_kich_hoat: 0,
    da_kich_hoat: 1,
  },
  PRICES_STATUS_LIST: [
    { value: 0, name: 'Chưa kích hoạt', color: '#e63900', textColor: '#FFF' },
    { value: 1, name: 'Đã kích hoạt', color: '#0059b3', textColor: '#FFF' },
  ],
  PRICES_GLOBAL: {
    private: 0,
    public: 1,
  },
  PRICES_GLOBAL_LIST: [
    { value: 0, name: 'Giá riêng', color: '#e63900', textColor: '#FFF' },
    { value: 1, name: 'Giá chung', color: '#0059b3', textColor: '#FFF' },
  ],
  EXPORT_RECEIPT_STATUS: [
    { value: 0, name: 'Chưa xuất', color: '#e63900' },
    { value: 1, name: 'Đã xuất', color: '#0059b3' }
  ],
  FLAG_TYPE: {
    cham_phat_hang: 1,
    cham_nhap_kho_tq: 2,
    cham_nhap_kho_vn: 3,
    tien_mua_that: 4,
  },
  FLAG_TYPE_LIST: [
    { value: 1, name: 'Chậm phát hàng' },
    { value: 2, name: 'Chậm nhập kho TQ' },
    { value: 3, name: 'Chậm nhập kho VN' },
    { value: 21, name: 'Mua thật lớn hơn báo giá' },
  ],
  SMS_TYPE_LIST: [ 
    { name: "Thành công", value: 1, color: '#0059b3' }, 
    { name: "Thất bại", value: 2, color: '#d9534f' }
  ],
  FEE_TYPE_LIST: [
    { value: 1, name: 'Theo cân', color: '#e63900' },
    { value: 2, name: 'Theo khối', color: '#0059b3' }
  ],
};