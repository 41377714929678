<template>
  <v-card class="elevation-0" style="width: min-content;">
    <v-app-bar flat dense color="transparent">
      <v-toolbar-title>
        <h5>Thông báo</h5>
      </v-toolbar-title>
    </v-app-bar>
    <v-divider></v-divider>
    <v-card-text class="pa-0">
      <v-list two-line class="pa-0">
        <template v-if="items.length == 0">
          <v-list-item>
             <v-list-item-content>
               <v-list-item-subtitle>Bạn không có thông báo nào!</v-list-item-subtitle>
             </v-list-item-content>
          </v-list-item>
        </template>
        <template v-for="item in items">
          <v-list-item avatar :key="item.ID" @click="handleClick(item.ID, item.OrderID, item.OrderType)">
            <v-list-item-avatar>
              <img :src="senderRole(item.SenderID, item.SenderUsername) | comment_avatar" alt="">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="senderName(item.SenderID, item.SenderUsername)"></v-list-item-title>
              <v-list-item-subtitle v-bind:class="{'font-weight-bold': item.Status != 1}" v-html="item.Message"></v-list-item-subtitle>
              <v-list-item-subtitle>{{ item.CreatedDate | display_date }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-divider></v-divider>
      <v-btn block text class="ma-0" @click="displayAll">Xem thêm</v-btn>
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
import { comment_avatar } from "@/commons/hpod";
import { display_date } from "@/commons/formater";
import { mapState } from "vuex";
export default {
  filters: { comment_avatar, display_date },
  computed: {
    ...mapState({
      staffs: state => state.commons.all_users,
      items: state => state.notification.top5.data
    }),
  },
  methods: {
    handleClick(id, orderId, orderType) {
      let item = _.find(this.items, {'ID': id});
        this.$store.dispatch("notification/setDetail", {
          'data': {'ID': id, 'Status': 1},
          'id': id
        });
      item.Status = 1;
      if(!orderType || orderType == 1) {
        let routeData = this.$router.resolve({
          name: "OrderDetail",
          params: {
            Pid: orderId,
            title: `Chi tiết đơn hàng (#${orderId})`
          }
        });
        window.open(routeData.href, "_blank");
      } else if (orderType == 3) {
            let routeData = this.$router.resolve({
            name: "WithdrawHistory"
            });
            window.open(routeData.href, "_blank");
      } else if (orderType == 4) {
        let routeData = this.$router.resolve({
          name: "ComplainDetail",
          params: {
            Pid: orderId,
            title: `Chi tiết khiếu nại (#${orderId})`
          }
        });
        window.open(routeData.href, "_blank");
      } else {
        let routeData = this.$router.resolve({
          name: "DepositDetail",
          params: {
            Pid: orderId,
            title: `Chi tiết đơn hàng (#${orderId})`
          }
        });
        window.open(routeData.href, "_blank");
      }
    },
    senderRole(senderId, senderName) {
      const staff = _.find(this.staffs, {'ID': senderId, 'Username': senderName});
      return staff ? staff.RoleType : null;
    },
    senderName(senderId, senderName) {
      const staff = _.find(this.staffs, {'ID': senderId});
      return staff ? staff.FullName : senderName;
    },
    displayAll() {
      let routeData = this.$router.resolve({
        name: "NotificationList"
      });
      window.open(routeData.href, "_blank");
    }
  },
};
</script>
