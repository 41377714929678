import { sendSuccessNotice, sendErrorNotice, getCurrentTime } from '@/commons/utils';
import { request_detail, request_list, update_detail, create_detail, remove_detail } from '@/commons/hpod';
import Vue from 'vue';
const endpoint = 'complain'
import configs from '@/configs'
import moment from "moment"
// initial state
const state = {
    loading: false,
    all: {},
    selected: {},
};

// getters
const getters = {};

// actions
const actions = {
    getList({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list(endpoint, data => {
            commit('setList', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        request_detail(endpoint, data => {
            commit('setDetail', data);
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    setDetail({ commit }, data) {
        commit("setLoading", { loading: true });
        delete data.CreatedDate;
        delete data.CreatedBy;
        data.ModifiedDate = getCurrentTime().format(configs.SQL_DATE_FORMAT);
        data.ModifiedBy = this.state.authentication.user.username;
        update_detail(endpoint, res => {
            if (res.data.status === 'fail') {
                sendErrorNotice('Không thể cập nhật khiếu nại.')
            }
            else {
                sendSuccessNotice('Cập nhật khiếu nại thành công.')
            }
            commit("setLoading", { loading: false });
        }, Vue.http, data, data.ID, this.state.authentication.token);
    },
};

// mutations
const mutations = {
    setList(state, data) {
        state.all = data;
    },
    setDetail(state, item) {
        state.selected = item.data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
