import { request_detail, request_list, update_detail, create_detail, remove_detail } from '@/commons/hpod';
import { sendSuccessNotice, sendErrorNotice } from '@/commons/utils';
import Vue from 'vue';
const endpoint = 'notifications'
const viewTitle = 'thông báo'

// initial state
const state = {
    loading: false,
    all: {},
    top5: {},
    selected: {
        ID: 0,
        AmountFrom: 0,
        AmountTo: 0,
        FeePercent: 0
    }
};

// getters
const getters = {};

// actions
const actions = {
    getList({ commit, dispatch }, param) {
        commit("setLoading", { loading: true });
        param.filter.push({
            column: "ReceivedID",
            value: this.state.authentication.user.userid,
            condition: "equal"
        });
        param.bell = false;
        request_list(endpoint, data => {
            commit('setList', data);
            commit("setLoading", { loading: false });
            dispatch("getTop5");
        }, Vue.http, param, this.state.authentication.token);
    },
    getTop5({ commit }) {
        var param = {
            filter: [{
                column: "Status",
                value: 0,
                condition: "equal"
            },
            {
                column: "ReceivedID",
                value: this.state.authentication.user.userid,
                condition: "equal"
            }],
            pagination: {
                page: 1,
                itemsPerPage: 5,
                sortBy: ['CreatedDate'],
                sortDesc: [1]
            },
            bell: true
        };
        request_list(endpoint, ret => {
            commit('setTop5', ret);
        }, Vue.http, param, this.state.authentication.token);
    },
    getDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        request_detail(endpoint, data => {
            commit('setDetail', data);
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    setDetail({ dispatch }, {data, id, updateBell = true}) {
        if(id > 0) {
            data.ModifiedBy = this.state.authentication.user.username;
            update_detail(endpoint, res => {
                
            }, Vue.http, data, id, this.state.authentication.token);
        }
        else {
            data.CreatedBy = this.state.authentication.user.username;
            delete data.ID;
            create_detail(endpoint, res => {
                
            }, Vue.http, data, this.state.authentication.token);
        }

        if(updateBell) {
            dispatch("getTop5");
        }
    },
    removeDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        remove_detail(endpoint, res => {
            
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
};

// mutations
const mutations = {
    setList(state, data) {
        state.all = data;
    },
    setTop5(state, ret) {
        state.top5 = ret;
    },
    setDetail(state, item) {
        state.selected = item.data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
