import Crud from './crud'
var crud = new Crud('export_receipt_list', 'xuất kho vn');

const state = {
    ...crud.state,
    selected: {
        ID: 0
    }
};
const getters = {
    ...crud.getters,
};
const actions = {
    ...crud.actions,
};
const mutations = {
    ...crud.mutations,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};