import paths from '@/router/paths';
import VueRouter from 'vue-router'
const { isNavigationFailure, NavigationFailureType } = VueRouter

export default [
  {
    name: 'APP_LOGIN_SUCCESS',
    callback: function (e) {
      this.snackbar = {
        show: true,
        color: 'success',
        text: 'Đăng nhập thành công'
      };
      setTimeout(this.$router.replace({ path: '/dashboard' }), 100);
    }
  },
  {
    name: 'APP_LOGOUT',
    callback: function (e) {
      this.snackbar = {
        show: true,
        color: 'success',
        text: 'Đăng xuất thành công'
      };
      localStorage.clear();
      setTimeout(this.$router.replace({ path: '/login' }), 100);
    }
  },
  {
    name: 'APP_PAGE_LOADED',
    callback: function (e) {
    }
  },
  {
    name: 'APP_AUTH_FAILED',
    callback: function (e) {
    localStorage.clear();
    this.$router.replace({ path: '/login' });
    // this.$message.error('Token has expired');
    }
  },
  {
    name: 'APP_BAD_REQUEST',
    // @error api response data
    callback: function (msg) {
      // this.$message.error(msg);
    }
  },
  {
    name: 'APP_ACCESS_DENIED',
    // @error api response data
    callback: function (msg) {
      // this.$message.error(msg);
      this.$router.push('/forbidden');
    }
  },
  {
    name: 'APP_RESOURCE_DELETED',
    // @error api response data
    callback: function (msg) {
      // this.$message.success(msg);
    }
  },
  {
    name: 'APP_RESOURCE_UPDATED',
    // @error api response data
    callback: function (msg) {
      this.snackbar = {
        show: true,
        color: 'primary',
        text: msg
      };
    }
  },
  {
    name: 'APP_RESOURCE_FAILED',
    // @error api response data
    callback: function (msg) {
      this.snackbar = {
        show: true,
        timeout: 10000,
        color: '#dc3545',
        text: msg
      };
    },
  },
  {
    name: 'APP_NOTIFY',
    // @error api response data
    callback: function (msg) {
      this.snackbar = {
        show: true,
        timeout: 6000,
        color: '#3E8AEB',
        text: msg
      };
    }
  },
  {
    name: 'APP_NAVIGATE',
    callback: function (viewName) {
      const item = _.find(paths, {name: viewName})
      if(item) {
        this.$router.replace({ path: item.path });
      }
    }
  },
  {
    name: 'PRE_SAVE_DATA',
    callback: function () {
      this.$root.$emit('REQUEST_SAVE_DATA');
    }
  },
  {
    name: 'OTHER_ACTION',
    callback: function () {
      this.$root.$emit('REQUEST_OTHER_ACTION');
    }
  },
  {
    name: 'REQUEST_REFRESH_DATA',
    callback: function () {
      this.$root.$emit('REQUEST_REFRESH_DATA');
    }
  },
  {
    name: 'REQUEST_DELETE_DATA',
    callback: function (id, message = '') {
      this.delDialog = true;
      this.delId = id;
      this.delMessage = message;
    }
  },
  {
    name: 'REQUEST_CONFIRM_ACTION',
    callback: function (confirmAction, id, message = '') {
      this.delDialog = true;
      this.delId = id;
      this.delMessage = message;
      this.confirmAction = confirmAction;
    }
  },
];
