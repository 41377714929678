import { request_detail, request_list, update_detail, create_detail, remove_detail } from '@/commons/hpod';
import { sendSuccessNotice, sendErrorNotice, refreshResource } from '@/commons/utils';
import Vue from 'vue';
const endpoint = 'storemanager'

// initial state
const state = {
    loading: false,
    all: {},
    listOfDay: {},
    selected: {
        id: 0,
        TransactionCode: '',
        FeeShip: 0,
    }
};

// getters
const getters = {};

// actions
const actions = {
    getList({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list(endpoint, data => {
            commit('setStoreManagerList', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getListOfDay({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list(endpoint, data => {
            commit('setStoreManagerListOfDay', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        request_detail(endpoint, data => {
            commit('setDetail', data);
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    getDetailByCode({ commit }, code, callback) {
        var param = {
            filter: [{
                column: "TransactionCode",
                value: code,
                condition: "s_equal"
            }]
        };
        request_list(endpoint, ret => {
            callback(ret);
        }, Vue.http, param, this.state.authentication.token);
    },
    setDetail({ commit }, {data, id}) {
        if(id > 0) {
            data.ModifiedBy = this.state.authentication.user.username;
            update_detail(endpoint, res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice('Không thể cập nhật mã bill.')
                }
                else {
                    sendSuccessNotice('Cập nhật mã bill thành công.')
                }
            }, Vue.http, data, id, this.state.authentication.token);
        }
        else {
            data.CreatedBy = this.state.authentication.user.username;
            delete data.ID;
            create_detail(endpoint, res => {
                if(res.status == 204) {
                    sendErrorNotice('Mã bill đã tồn tại.')                    
                }
                else if (res.data.status === 'fail') {
                    sendErrorNotice('Không thể thêm mới mã bill.')
                }
                else {
                    sendSuccessNotice('Thêm mới mã bill thành công.');
                    refreshResource();
                }
            }, Vue.http, data, this.state.authentication.token);
        }
    },
    removeDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        remove_detail(endpoint, res => {
            if (res.data.status === false) {
                sendErrorNotice('Không thể xóa mã bill.')
            }
            else {
                sendSuccessNotice('Đã xóa mã bill thành công.')
                commit("removeItemFromListOfDay", id);
            }
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    }
};

// mutations
const mutations = {
    updateStoreManager(state, item) {
        state.listOfDay.total +=1;
        state.listOfDay.data.unshift(item);        
    },
    setStoreManagerList(state, data) {
        state.all = data;
    },
    removeItemFromListOfDay(state, id){
        _.remove(state.listOfDay.data, {
            'ID': id
        });
        state.listOfDay.total -=1;
    },
    setStoreManagerListOfDay(state, data) {
        state.listOfDay = data;
    },
    setDetail(state, item) {
        state.selected = item.data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
