import Vue from 'vue';
import Router from 'vue-router';
import paths from './paths';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

Vue.use(Router);
const router =  new Router({
  base: '/',
  mode: 'hash',
  linkActiveClass: 'active',
  routes: paths,
  mode: 'history'
});
// router gards
router.beforeEach((to, from, next) => {
  NProgress.start();
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
        next({
            path: '/login',
            params: { nextUrl: to.fullPath }
        })
    } else {
        let user = JSON.parse(localStorage.getItem('user'))
        if(to.matched.some(record => record.meta.is_admin)) {
            if(user.is_admin == 1){
                next()
            }
            else{
                next({ name: 'dashboard'})
            }
        }else {
            try {
                let vuex = localStorage.getItem('vuex');
                vuex = JSON.parse(vuex);
                let role = _.reduce(_.filter(vuex.roles.all.data, function(obj) {
                    return (obj.RoleType & user.roletype) == obj.RoleType;  
                }), function(result, obj) {
                    result.Module = result.Module | obj.Module;
                    result.Report = result.Report | obj.Report;
                    return result;
                }, {Module: 0, Report: 0})

                if(to.meta.roleId & role.Module && (!to.meta.reportRoleId || (to.meta.reportRoleId & role.Report))) {
                    next()
                }
                else {
                    next({name: 'NotFound'})
                }
            } catch (error) {
                next({
                    path: '/login',
                    params: { nextUrl: to.fullPath }
                })
            }
        }
    }
  } else if(to.matched.some(record => record.meta.public)) {
      if(localStorage.getItem('jwt') == null){
          next()
      }
      else{
          next({ name: 'dashboard'})
      }
  }else {
      next() 
  }
});

router.afterEach((to, from) => {
  NProgress.done();
});

export default router;
