import { request_detail, request_list, update_detail, create_detail, remove_detail } from '@/commons/hpod';
import { sendSuccessNotice, sendErrorNotice } from '@/commons/utils';
import Vue from 'vue';
import moment from "moment";
const endpoint = 'accountantcumulation'
const viewTitle = 'Luồng thu chi'

// initial state
const state = {
    loading: false,
    all: {},
    selected: {
        ID: 0,
        AccountantDate: 0,
        IncomeAmount: 0,
        Tuikuan: 0,
        OutcomePurchase: 0,
        OutcomeOther: 0,
        Note: ''
    }
};

// getters
const getters = {};

// actions
const actions = {
    getList({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list(endpoint, data => {
            commit('setList', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getDetail({ commit }, accountantDate) {
        commit("setLoading", { loading: true });
        request_detail(endpoint, data => {
            commit('setDetail', data);
            commit("setLoading", { loading: false });
        }, Vue.http, accountantDate, this.state.authentication.token);
    },
    setDetail({ dispatch }, {data, accountantDate, param}) {
        data.AccountantDate = accountantDate;
        update_detail(endpoint, res => {
            if (res.data.status === 'fail') {
                sendErrorNotice(`Không thể cập nhật ${viewTitle}.`)
            }
            else {
                dispatch("getList", param);
                sendSuccessNotice(`Cập nhật ${viewTitle} thành công.`)
            }
        }, Vue.http, data, accountantDate, this.state.authentication.token);
    },
    removeDetail({ commit }, accountantDate) {
        commit("setLoading", { loading: true });
        remove_detail(endpoint, res => {
            if (res.data.status === false) {
                sendErrorNotice(`Không thể xóa ${viewTitle}.`)
            }
            else {
                sendSuccessNotice(`Đã xóa ${viewTitle} thành công.`)
            }
            commit("setLoading", { loading: false });
        }, Vue.http, accountantDate, this.state.authentication.token);
    },
};

// mutations
const mutations = {
    setList(state, data) {
        state.all = data;
    },
    setDetail(state, item) {
        state.selected = item.data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
