<template>
<v-layout row class="align-center layout pl-0 app--page-header">
    <div class="page-header-left">
        <v-breadcrumbs divider="-" :items="breadcrumbs">
            <template v-slot:item="props">
                <v-breadcrumbs-item :href="props.item.href" :class="[props.item.disabled && 'disabled']">
                    <h3>{{ props.item.text }}</h3>
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
        <template v-if="breadcrumbs.length == 0">
            <v-breadcrumbs>
                <v-breadcrumbs-item>
                    <h3>{{ routeName }}</h3>
                </v-breadcrumbs-item>
            </v-breadcrumbs>
        </template>
    </div>
    <v-spacer></v-spacer>
    <div class="page-header-right pr-3">
        <v-btn class="mr-3" small v-if="$route.meta.otherButton && !options.invisibleOtherButton" @click="otherAction">
            <v-icon class="mr-3">{{$route.meta.otherButtonIcon ? $route.meta.otherButtonIcon : 'far fa-save'}}</v-icon>  {{$route.meta.otherButtonText ? $route.meta.otherButtonText : 'Log'}}
        </v-btn>
        <v-btn class="mr-3" small v-if="$route.meta.saveButton && !options.invisibleSaveButton" @click="saveData" v-shortkey.once="['ctrl', 's']" @shortkey="saveData">
            <v-icon class="mr-3">{{$route.meta.saveButtonIcon ? $route.meta.saveButtonIcon : 'far fa-save'}}</v-icon>  {{$route.meta.saveButtonText ? $route.meta.saveButtonText : 'Lưu'}}
        </v-btn>
        <v-btn class="mr-3" small v-if="$route.meta.createButton && !options.invisibleCreateButton" @click="createData" v-shortkey.once="['alt', 'n']" @shortkey="createData">
            <v-icon>{{$route.meta.createButtonIcon ? $route.meta.createButtonIcon : 'fa-plus'}}</v-icon>  {{$route.meta.createButtonText ? $route.meta.createButtonText : (isChinessLanguage ? '新创建' : 'Tạo mới') }}
        </v-btn>
        <v-btn class="mr-3" small v-if="$route.meta.refreshButton" @click="refreshData" v-shortkey.once="['alt','f5']" @shortkey="refreshData">
            <v-icon class="text--secondary">fa-sync</v-icon>
        </v-btn>
    </div>
</v-layout>
</template>

<script>
import menu from "@/api/menu";
import paths from "@/router/paths";
import configs from "@/configs";
import {
    buildBreadcrumb
} from "@/commons/utils";
export default {
     data() {
        return {
            options: {}
        }
     },
    computed: {
        routeName() {
            var title = this.$route.params.title ? this.$route.params.title : this.$route.meta.title;
            return title ? title : this.$route.name;
        },
        breadcrumbs: function () {
            let breadcrumbs = [];
            let routeName = this.$route.name;
            breadcrumbs = buildBreadcrumb(routeName, this.$route.path, this.$route.params.Pid, this.$route.params.title, menu, paths, this.isChinessLanguage);
            return breadcrumbs;
        },
        isChinessLanguage() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreTQ) == configs.ROLE_TYPE.StoreTQ && this.$store.state.authentication.user.ischinesslanguage;
        }
    },
    methods: {
        otherAction() {
            window.getApp.$emit('OTHER_ACTION');
        },
        saveData() {
            window.getApp.$emit('PRE_SAVE_DATA');
        },
        createData() {
          this.$root.$emit('REQUEST_CREATE_DATA');
        },
        refreshData() {
          this.$root.$emit('REQUEST_REFRESH_DATA');
        },
        changeOption(options) {
            this.options = options;
        }
    },
    mounted() {
        this.$root.$on("REQUEST_ROOT_OPTION", this.changeOption);
    }
};
</script>
