<template>
    <div>
        <v-text-field type="number" :rules="rules" :step="decimal == undefined || decimal == 0 ? 1 : .1" v-if="isInputActive" v-bind:class='showClass' :color="color" :label="label" v-model="displayValue" @blur="on_blur" @focus="isInputActive = true" :readonly="readonly" :disabled="Number(disabled) === 1 || disabled === ''" @keyup.enter="press_enter"/>
        <v-text-field type="text" :rules="rules" v-if="!isInputActive" v-bind:class='showClass' :color="color" :label="label" v-model="displayValue" @blur="on_blur" @focus="isInputActive = true" :readonly="readonly" :disabled="Number(disabled) === 1 || disabled === ''" @keyup.enter="press_enter"/>
    </div>
</template>
<script>
export default {
    name: 'h-currency-input',
    // props: ["value", "label", "disabled", "decimal", "showClass", "readonly", "color", "rules", autoEmit = { type: Boolean, default: true }],
    props: {
        value: Number,
        label: String,
        disabled: Boolean,
        decimal: Number,
        showClass: String,
        readonly: Boolean,
        color: String,
        rules: Array,
        autoEmit: { type: Boolean, default: true }
    },
    data: function() {
        return {
            isInputActive: false,
            tempValue: 0,
        }
    },
    watch: {
        value() {
            this.tempValue = this.value;
        }
    },
    computed: {
        displayValue: {
            get: function() {
                if (this.isInputActive) {
                    // Cursor is inside the input field. unformat display value for user
                    return (this.value || '').toString()
                } else {
                    // User is not modifying now. Format display value for user interface
                    var parseValue = Number(this.value)
                    return this.value ? parseValue.toFixed(this.decimal !== undefined ? this.decimal : 2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") : '0'
                }
            },
            set: function(modifiedValue) {
                // Recalculate value after ignoring "$" and "," in user input
                let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""))
                // Ensure that it is not NaN
                if (isNaN(newValue)) {
                    newValue = 0
                }
                // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
                // $emit the event so that parent component gets it
                if(this.autoEmit) {
                    this.$emit('input', newValue)
                }
                else{
                    this.tempValue = newValue;
                }
            }
        }
    },
    methods: {
        press_enter(e) {
            if(!this.autoEmit) {
                this.$emit('input', Number(this.tempValue))
            }
            else {
                this.$emit('press_enter', e)
            }
        },
        on_blur() {
            this.isInputActive = false;
            if(!this.autoEmit) {
                this.$emit('input', Number(this.tempValue))
            }
            else {
                this.$emit('on_blur')
            }
        }
    }
}
</script>
