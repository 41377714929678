import Vue from "vue";
import Vuex from "vuex";
import main_order from './modules/main_order'
import customer from './modules/customer'
import customerLevel from './modules/customerLevel'
import authentication from './modules/authentication'
import rechargeHistory from './modules/rechargeHistory'
import paymentHistory from './modules/paymentHistory'
import orderComment from './modules/orderComment'
import orderTransaction from './modules/orderTransaction'
import payOrder from './modules/payOrder'
import withDraw from './modules/withDraw'
import deposit from './modules/deposit'
import complain from './modules/complain'
import storeManager from './modules/storeManager'
import bigPackage from './modules/bigPackage'
import feeBuy from './modules/feeBuy'
import feeWeight from './modules/feeWeight'
import feeVolume from './modules/feeVolume'
import configuration from './modules/configuration'
import staff from './modules/staff'
import roles from './modules/roles'
import notification from './modules/notification'
import historypaywallet from './modules/historypaywallet'
import changedHistories from './modules/changedHistories'
import bigPackageVn from './modules/bigPackageVn'
import commons from './modules/commons'
import report from './modules/report'
import dashboard from './modules/dashboard'
import warehouse from './modules/warehouse'
import deliverable from './modules/deliverable'
import accountant_order from './modules/accountant_order'
import accountant_cumulation from './modules/accountant_cumulation'
import accountant_cumulation_vnd from './modules/accountant_cumulation_vnd'
import ordercn from './modules/ordercn'
import payservice from './modules/payservice'
import tuikuan from './modules/tuikuan'
import storereport from './modules/storereport'
import chinaStore from './modules/chinaStore'
import orderFlag from './modules/orderFlag'
import flag from './modules/flag'
import feeCheck from './modules/feeCheck'
import feeGuarantee from './modules/feeGuarantee'
import feeReinforce from './modules/feeReinforce'
import prices from './modules/prices'
import financeLog from './modules/financeLog'
import exportReceipt from './modules/exportReceipt'
import exportReceiptList from './modules/exportReceiptList'
import addressBook from './modules/addressBook'
import bank_message from './modules/bank_message'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    customer,
    customerLevel,
    main_order,
    authentication,
    rechargeHistory,
    paymentHistory,
    orderComment,
    orderTransaction,
    payOrder,
    withDraw,
    deposit,
    complain,
    storeManager,
    bigPackage,
    bigPackageVn,
    feeBuy,
    feeWeight,
    configuration,
    staff,
    roles,
    notification,
    historypaywallet,
    changedHistories,
    report,
    dashboard,
    warehouse,
    deliverable,
    accountant_order,
    accountant_cumulation,
    accountant_cumulation_vnd,
    ordercn,
    feeVolume,
    payservice,
    tuikuan,
    storereport,
    chinaStore,
    orderFlag,
    flag,
    feeCheck,
    feeGuarantee,
    feeReinforce,
    prices,
    financeLog,
    exportReceipt,
    exportReceiptList,
    addressBook,
    bank_message,
    commons
  },
  state: {},
  mutations: {},
  actions: {},
  strict: false,
  plugins: [createPersistedState({paths:['authentication', 'commons', 'roles'], storage: window.localStorage})]
});
