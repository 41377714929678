import { sendSuccessNotice, sendErrorNotice, getCurrentTime, refreshResource } from '@/commons/utils';
import { request_detail, request_list, update_detail, create_detail, remove_detail } from '@/commons/hpod';
import configs from '@/configs'
import Vue from 'vue';
const endpoint = 'customers'

// initial state
const state = {
    loading: false,
    all: {},
    master: {},
    selected: {
        ID: 0
    }
};

// getters
const getters = {};

// actions
const actions = {
    getCustomerList({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list(endpoint, data => {
            if(param.is_master) {
                commit('setMasterList', data);
            }
            else {
                commit('setCustomerList', data);
            }
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getCustomerDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        request_detail(endpoint, data => {
            commit('setCustomerDetail', data);
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    removeCustomerDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        remove_detail(endpoint, res => {
            if (res.data.status === false) {
                sendErrorNotice('Không thể xóa khách hàng.')
            }
            else {
                sendSuccessNotice('Đã xóa khách hàng thành công.')
                refreshResource();
            }
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    setCustomerDetail({ commit }, {data, id, resetPwd}) {
        data.ModifiedDate = getCurrentTime().format(configs.SQL_DATE_FORMAT);
        data.ModifiedBy = this.state.authentication.user.userid;
        commit("setLoading", { loading: true });
        if(id > 0) {
            delete data.CreatedDate;
            delete data.Createdby;
            update_detail(endpoint, res => {
                if (res.status === 202) {
                    sendErrorNotice(res.reason)
                }
                else if (res.data.status === 'fail') {
                    sendErrorNotice('Không thể cập nhật khách hàng.')
                }
                else if(resetPwd) {
                    sendSuccessNotice('Mật khẩu đã được reset về 123@123.')
                }
                else {
                    sendSuccessNotice('Cập nhật khách hàng thành công.')
                    refreshResource();
                }
                commit("setLoading", { loading: false });
            }, Vue.http, data, id, this.state.authentication.token);
        }
        else {
            // data.CreatedDate = getCurrentTime().format(configs.SQL_DATE_FORMAT);
            data.Createdby = this.state.authentication.user.userid;
            create_detail(endpoint, res => {
                if (res.status === 202) {
                    sendErrorNotice(res.reason)
                }
                else if (res.data.status === 'fail') {
                    sendErrorNotice('Không thể thêm mới khách hàng.')
                }
                else {
                    sendSuccessNotice('Thêm mới khách hàng thành công.');
                    refreshResource();
                }
                commit("setLoading", { loading: false });
            }, Vue.http, data, this.state.authentication.token);
        }
    },
};

// mutations
const mutations = {
    setCustomerList(state, data) {
        state.all = data;
    },
    setMasterList(state, data) {
        state.master = data;
    },
    setCustomerDetail(state, item) {
        state.selected = item.data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
