import { request_list } from '@/commons/hpod';
import Vue from 'vue';
const endpoint = 'changedhistory'

// initial state
const state = {
    all: {},
};

// getters
const getters = {};

// actions
const actions = {
    getList({ commit }, param) {
        request_list(endpoint, data => {
            commit('setList', data);
        }, Vue.http, param, this.state.authentication.token);
    }
};

// mutations
const mutations = {
    setList(state, data) {
        state.all = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
