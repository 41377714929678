import { request_detail, request_list, update_detail, create_detail, remove_detail } from '@/commons/hpod';
import { sendSuccessNotice, sendErrorNotice, refreshResource } from '@/commons/utils';
import Vue from 'vue';
export default class {
    constructor(endpoint, viewTitle) {
        this.endpoint = endpoint;
        this.viewTitle = viewTitle;
        this.state = {
            loading: false,
            all: {},
            selected: {}
        };
        this.getters = {};
        this.actions = {
            getList({ commit }, param) {
                commit("setLoading", { loading: true });
                request_list(endpoint, data => {
                    commit('setList', data);
                    commit("setLoading", { loading: false });
                }, Vue.http, param, this.state.authentication.token);
            },
            getDetail({ commit }, id) {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        commit("setLoading", { loading: true });
                        request_detail(endpoint, data => {
                            commit('setDetail', data);
                            commit("setLoading", { loading: false });
                            resolve(data.data)
                        }, Vue.http, id, this.state.authentication.token);
                    }, 100)
                })
            },
            setDetail({ commit }, { data, id }) {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        if (id > 0) {
                            update_detail(endpoint, res => {
                                if(res.status == 202) {
                                    sendErrorNotice(res.reason);           
                                }
                                else if (res.data.status === 'fail') {
                                    reject(res.data);
                                    sendErrorNotice(`Không thể cập nhật ${viewTitle}.`)
                                }
                                else {
                                    resolve(res);
                                    sendSuccessNotice(`Cập nhật ${viewTitle} thành công.`)
                                }
                                refreshResource();
                            }, Vue.http, data, id, this.state.authentication.token);
                        }
                        else {
                            delete data.ID;
                            create_detail(endpoint, res => {
                                if(res.status == 202) {
                                    sendErrorNotice(res.reason);
                                }
                                else if (res.data.status === 'fail') {
                                    reject(res.data);
                                    sendErrorNotice(`Không thể thêm mới ${viewTitle}.`)
                                }
                                else {
                                    commit('setDetail', res);
                                    resolve(res);
                                    sendSuccessNotice(`Thêm mới ${viewTitle} thành công.`);
                                }
                                refreshResource();
                            }, Vue.http, data, this.state.authentication.token);
                        }
                    }, 100)
                })
            },
            removeDetail({ commit }, id) {
                commit("setLoading", { loading: true });
                remove_detail(endpoint, res => {
                    if (res.data.status === false) {
                        sendErrorNotice(`Không thể xóa ${viewTitle}.`)
                    }
                    else {
                        sendSuccessNotice(`Đã xóa ${viewTitle} thành công.`)
                        refreshResource();
                    }
                    commit("setLoading", { loading: false });
                }, Vue.http, id, this.state.authentication.token);
            }
        };
        this.mutations = {
            setList(state, data) {
                state.all = data;
            },
            setDetail(state, item) {
                state.selected = item.data;
            },
            setLoading(state, { loading }) {
                state.loading = loading;
            }
        };
    }
}