import Crud from './crud'
var crud = new Crud('bankmessages', 'nhật ký tin nhắn sms');

const state = {
    ...crud.state,
};
const getters = {
    ...crud.getters,
};
const actions = {
    ...crud.actions,
};
const mutations = {
    ...crud.mutations,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};