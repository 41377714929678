import { request_list, update_detail, create_detail, request_detail, remove_detail } from '@/commons/hpod';
import { sendSuccessNotice, sendErrorNotice } from '@/commons/utils';
import Vue from 'vue';
const viewTitle = 'báo cáo'
// initial state
const state = {
    loading: false,
    orderRevenue: [],
    orderRevenueV2: [],
    orderFeeBuy: [],
    orderMoney: [],
    orderTransport: {},
    recharge: {},
    financeReport: {
        data: [],
        total: 0,
        summary: {}
    },
    debtReport: {
        data: [],
        total: 0,
        summary: {}
    },
    debtReportDetail: {},
    financeReportDetail: {},
    generalReport: {},
    customerReport: {},
    orderWarning: {},
    commissionReport: {
        data: [],
        total: 0,
        summary: {}
    },
};

// getters
const getters = {};

// actions
const actions = {
    getOrderRevenue({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list('report/orderrevenue', data => {
            commit('setOrderRevenue', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getOrderFeeBuy({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list('report/orderfeebuy', data => {
            commit('setOrderFeeBuy', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    setOrderFeeBuy({ commit }, {data, id, notify = ''}) {
        if(id > 0) {
            data.ModifiedBy = this.state.authentication.user.username;
            update_detail('report/orderfeebuy', res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice(`Không thể cập nhật ${viewTitle}.`)
                }
                else if(notify == ''){
                    sendSuccessNotice(`Cập nhật ${viewTitle} thành công.`)
                }
                else {
                    sendSuccessNotice(notify)
                }
            }, Vue.http, data, id, this.state.authentication.token);
        }
        else {
            data.CreatedBy = this.state.authentication.user.username;
            create_detail('report/orderfeebuy', res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice(`Không thể thêm mới ${viewTitle}.`)
                }
                else {
                    commit('updateOrderFeeBuy', res.data);
                    sendSuccessNotice(`Thêm mới ${viewTitle} thành công.`);
                }
            }, Vue.http, data, this.state.authentication.token);
        }
    },
    getOrderMoney({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list('report/ordermoney', data => {
            commit('setOrderMoney', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    setOrderMoney({ commit, dispatch }, {data, id, param, notify = ''}) {
        if(id > 0) {
            data.ModifiedBy = this.state.authentication.user.username;
            update_detail('report/ordermoney', res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice(`Không thể cập nhật ${viewTitle}.`)
                }
                else if(notify == ''){
                    sendSuccessNotice(`Cập nhật ${viewTitle} thành công.`)
                    dispatch("getOrderMoney", param);
                }
                else {
                    sendSuccessNotice(notify)
                    dispatch("getOrderMoney", param);
                }
            }, Vue.http, data, id, this.state.authentication.token);
        }
        else {
            data.CreatedBy = this.state.authentication.user.username;
            create_detail('report/ordermoney', res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice(`Không thể thêm mới ${viewTitle}.`)
                }
                else {
                    sendSuccessNotice(`Thêm mới ${viewTitle} thành công.`);
                    dispatch("getOrderMoney", param);
                }
            }, Vue.http, data, this.state.authentication.token);
        }
    },
    getFinanceReport({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list('report/finance', data => {
            commit('setFinanceReport', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getFinanceReportDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        request_detail('report/finance', data => {
            commit('setFinanceReportDetail', data.data);
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    setFinanceReport({ commit, dispatch }, {data, id, param, notify = ''}) {
        data.CreatedBy = this.state.authentication.user.username;
        create_detail('report/finance', res => {
            if (res.data.status === 'fail') {
                sendErrorNotice(`Không thể thêm mới lệnh.`)
            }
            else {
                sendSuccessNotice(`Thêm mới lệnh thành công.`);
                dispatch("getFinanceReport", param);
            }
        }, Vue.http, data, this.state.authentication.token);
    },
    getOrderTransport({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list('report/ordertransport', data => {
            commit('setOrderTransport', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getRecharge({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list('report/recharge', data => {
            commit('setRecharge', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getGeneralReport({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list('report/generalreport', data => {
            commit('setGeneralReport', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getCustomerReport({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list('report/customerreport', data => {
            commit('setCustomerReport', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getDebtReport({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list('report/debt', data => {
            commit('setDebtReport', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getDebtReportDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        request_detail('report/debt', data => {
            commit('setDebtReportDetail', data);
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    getOrderRevenueV2Report({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list('report/orderrevenue_v2', data => {
            commit('setOrderRevenueV2Report', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getOrderWarning({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list('orderwarning', data => {
            commit('setOrderWarning', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getCommissionReport({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list('report/commissionreport', data => {
            commit('setCommissionReport', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    removeFinanceReport({ commit, dispatch }, {id, param}) {
        commit("setLoading", { loading: true });
        remove_detail('report/finance', res => {
            if (res.data.status === false) {
                sendErrorNotice(`Không thể xóa lệnh.`)
            }
            else {
                sendSuccessNotice(`Đã xóa lệnh thành công.`)
                dispatch("getFinanceReport", param);
            }
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
};

// mutations
const mutations = {
    setOrderRevenue(state, data) {
        state.orderRevenue = data;
    },
    setOrderFeeBuy(state, data) {
        state.orderFeeBuy = data;
    },
    updateOrderFeeBuy(state, item) {
        let monthdata = _.find(state.orderFeeBuy.data, {'month': item.Month.toString()});
        if(monthdata){
            let existItem = _.find(monthdata.rows, {'CustomerServiceID': item.UID});
            if(existItem){
                existItem.ID = item.ID;
            }
        }
    },
    setOrderMoney(state, data) {
        state.orderMoney = data;
    },
    setFinanceReport(state, data) {
        state.financeReport = data;
    },
    setFinanceReportDetail(state, data) {
        state.financeReportDetail = data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
    setOrderTransport(state, data) {
        state.orderTransport = data;
    },
    setRecharge(state, data) {
        state.recharge = data;
    },
    setGeneralReport(state, data) {
        state.generalReport = data;
    },
    setCustomerReport(state, data) {
        state.customerReport = data;
    },
    setDebtReport(state, data) {
        state.debtReport = data;
    },
    setCommissionReport(state, data) {
        state.commissionReport = data;
    },
    setDebtReportDetail(state, item) {
        state.debtReportDetail = item.data;
    },
    setOrderRevenueV2Report(state, data) {
        state.orderRevenueV2 = data;
    },
    setOrderWarning(state, data) {
        state.orderWarning = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
