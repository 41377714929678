import { request_detail, request_list, update_detail, create_detail, remove_detail, request_header, export_list } from '@/commons/hpod';
import { sendSuccessNotice, sendErrorNotice, refreshResource, playSound } from '@/commons/utils';
import configs from '@/configs';
import Vue from 'vue';
var FileSaver = require('file-saver');
const endpoint = 'bigpackage'

// initial state
const state = {
    loading: false,
    notTransit: [],
    inTQ: [],
    all: {},
    selected: {
        ID: 0,
        PackageCode: '',
        ProductType: 5,
        ReceivePlaceID: 1,
        Weight: 0,
        Volume: 0,
        Amount: 0,
        Status: 0,
        Packages: []
    }
};

// getters
const getters = {};

// actions
const actions = {
    getList({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list(endpoint, data => {
            commit('setBigPackageList', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getListNotTransit({ commit }) {
        var param = {'not_tran': true}
        request_list(endpoint, data => {
            commit('setListNotTransit', data);
        }, Vue.http, param, this.state.authentication.token);
    },
    getListInTQ({ commit }) {
        var param = {'in_tq': true}
        request_list(endpoint, data => {
            commit('setListInTQ', data);
        }, Vue.http, param, this.state.authentication.token);
    },
    getDetail({ commit }, {id, param}) {
        commit("setLoading", { loading: true });
        request_detail(endpoint, data => {
            commit('setDetail', data);
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token, param);
    },
    setDetail({ dispatch, commit }, {data, id, param}) {
        if(id > 0) {
            data.ModifiedBy = this.state.authentication.user.username;
            update_detail(endpoint, res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice('Không thể cập nhật bao hàng.')
                }
                else {
                    sendSuccessNotice('Cập nhật bao hàng thành công.')
                    refreshResource();
                }
            }, Vue.http, data, id, this.state.authentication.token);
        }
        else {
            data.CreatedBy = this.state.authentication.user.username;
            delete data.ID;
            create_detail(endpoint, res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice('Không thể thêm mới bao hàng.')
                }
                else {
                    if(res.status == 204) {
                        sendErrorNotice(res.reason)
                    }
                    else {
                        sendSuccessNotice(data.Action == 'StoreExport' ? 'Xuất kho bao hàng thành công' : 'Thêm mới bao hàng thành công.');
                        commit('setDetail', res);
                        refreshResource();
                    }
                }
            }, Vue.http, data, this.state.authentication.token);
        }
    },
    removeDetail({ commit }, id) {
        if(id == 0) {
            return;
        }
        commit("setLoading", { loading: true });
        remove_detail(endpoint, res => {
            if (res.data.status === false) {
                sendErrorNotice('Không thể xóa bao hàng.')
            }
            else {
                sendSuccessNotice('Đã xóa bao hàng thành công.')
            }
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    addNewTrancode({dispatch}, {data, param}) {
        const header = {
            headers: request_header(this.state.authentication.token)
        };
       
        Vue.http.post(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/bigpackage/new-trancode-vn/`, data, header).then(function (res) {
            if(res.data.status == 204) {
                playSound('/vtb-error.mp3');
                sendErrorNotice(res.data.reason)
            }
            else {
                playSound('/vtb-success.mp3');
            }
            refreshResource();
        });
    },
    export({ commit }, param) {
        commit("setLoading", { loading: true });
        export_list(endpoint, res => {
            commit("setLoading", { loading: false });
            var blob = new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"});
            FileSaver.saveAs(blob, `DanhSachBaoHang_${new Date().getTime()}.xlsx`);
        }, Vue.http, param, this.state.authentication.token, 'export');
    },
    storeExportCode ({}, {data}) {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            const header = {
                headers: request_header(this.state.authentication.token)
            };
           
            Vue.http.post(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/bigpackage/store_export/`, data, header).then(function (res) {
                if(res.data.status == 204) {
                    sendErrorNotice(res.data.reason)
                }
                resolve(res.data)
                // refreshResource();
            });
          }, 100)
        })
    },
    receiptExportCode ({}, {data}) {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            const header = {
                headers: request_header(this.state.authentication.token)
            };
           
            Vue.http.post(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/bigpackage/receipt_export/`, data, header).then(function (res) {
                if(res.data.status == 406) {
                    sendErrorNotice(res.data.reason)
                }
                resolve(res.data)
                // refreshResource();
            });
          }, 100)
        })
    }
};

// mutations
const mutations = {
    setBigPackageList(state, data) {
        state.all = data;
    },
    setListNotTransit(state, data) {
        state.notTransit = data.data;
    },
    setListInTQ(state, data) {
        state.inTQ = data.data;
    },
    setDetail(state, item) {
        state.selected = item.data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
