<template>
  <v-app-bar fixed class="hpo_color">
    <v-app-bar-nav-icon @click.stop="handleDrawerToggle" class="hpo_color"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <v-menu
      offset-y
      origin="center center"
      class="elelvation-1 pr-3"
      :nudge-bottom="14"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn icon text v-on="on" class="mr-1">
          <v-badge color="red" overlap>
            <span v-if="totalNotification > 0" slot="badge">{{totalNotification}}</span>
            <v-icon medium color="rgb(37, 37, 37)">far fa-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <notification-list></notification-list>
    </v-menu>
    <v-menu
      offset-y
      origin="center center"
      :nudge-bottom="10"
      transition="scale-transition"
      class="pr-3"
    >
      <template v-slot:activator="{ on }">
        <v-btn large text v-on="on">
          <v-label>{{userName}}</v-label>
          <v-avatar size="30px">
            <img src="/android-icon-96x96.png" alt="Avatar" />
          </v-avatar>
        </v-btn>
      </template>
      <v-list class="pa-0">
        <v-list-item
          v-for="(item,index) in items"
          :to="!item.href ? { name: item.name } : null"
          :href="item.href"
          @click="item.click"
          ripple="ripple"
          :disabled="item.disabled"
          :target="item.target"
          rel="noopener"
          :key="index"
        >
          <v-list-item-action v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import NotificationList from "@/components/widgets/list/NotificationList";
import Util from "@/util";
import { mapState } from "vuex";
import moment from "moment";
import configs from "@/configs";
import _ from "lodash";
export default {
  name: "app-toolbar",
  components: {
    NotificationList
  },
  data: () => ({
    items: [
      {
        icon: "fa-user-circle",
        href: "/profile",
        title: "Profile",
        click: e => {
          this.$router.push({ path: "profile" });
        }
      },
      {
        icon: "fa-sign-out-alt",
        href: "#",
        title: "Logout",
        click: () => {
          window.getApp.$emit("APP_LOGOUT");
        }
      }
    ]
  }),
  computed: {
    toolbarColor() {
      return this.$vuetify.options.extra.mainNav;
    },
    userName() {
      return this.$store.state.authentication.user.username;
    },
    ...mapState({
      totalNotification: state => state.notification.top5.total,
      top5Notifications: state => state.notification.top5.data
    })
  },
  watch: {
    top5Notifications() {
      if(this.top5Notifications.length > 0) {
        const smsDate = moment(this.top5Notifications[0].CreatedDate);
        if(moment.duration(moment(new Date()).diff(smsDate)).asMinutes() < 1) {
          window.getApp.$emit('APP_NOTIFY', this.top5Notifications[0].Message);
        }
      }
    }
  },
  methods: {
    handleDrawerToggle() {
      window.getApp.$emit("APP_DRAWER_TOGGLED");
    },
    handleFullScreen() {
      Util.toggleFullScreen();
    },
    fetchData: _.debounce(function () {
      if(this.$store.state.authentication.token != ""){
        this.$store.dispatch("notification/getTop5");
      }
    }, 500, {
        'leading': true,
        'trailing': false
    }),
  },
  created() {
      this.timer = setInterval(this.fetchData, 1000*configs.SYSTEM_CONFIG.notification_time);
  },
  mounted() {
    if(this.$store.state.authentication.token != "") {
      this.$store.dispatch("commons/getAllUsers");
      this.fetchData();
    }
  },
  destroyed() {
    clearInterval(this.timer);
  }
};
</script>
