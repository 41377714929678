import { request_detail, request_list, update_detail, create_detail, remove_detail } from '@/commons/hpod';
import { sendSuccessNotice, sendErrorNotice } from '@/commons/utils';
import Vue from 'vue';
const endpoint = 'bigpackagevn'

// initial state
const state = {
    loading: false,
    trancodeDetail: {},
    all: {},
    selected: {
        ID: 0,
        PackageCode: "",
        BigPackageID: -1,
        Weight: 0,
        Volume: 0,
        Amount: 0
    }
};

// getters
const getters = {};

// actions
const actions = {
    getList({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list(endpoint, data => {
            commit('setBigPackageList', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getDetail({ commit }, param) {
        commit("setLoading", { loading: true });
        var id = param.packageId;
        delete param.packageId;
        request_detail(endpoint, data => {
            commit('setDetail', data);
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token, param);
    },
    getTrancodeDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        request_detail('bigpackagevn/trancode', data => {
            commit('setTrancodeDetail', data);
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    setDetail({ commit }, {data, id}) {
        delete data.Packages;
        if(id > 0) {
            data.ModifiedBy = this.state.authentication.user.username;
            update_detail(endpoint, res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice('Không thể cập nhật bao hàng.')
                }
                else {
                    sendSuccessNotice('Cập nhật bao hàng thành công.')
                }
            }, Vue.http, data, id, this.state.authentication.token);
        }
        else {
            data.CreatedBy = this.state.authentication.user.username;
            delete data.ID;
            create_detail(endpoint, res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice('Không thể thêm mới bao hàng.')
                }
                else {
                    commit("setDetail", res);
                    sendSuccessNotice('Thêm mới bao hàng thành công.');
                }
            }, Vue.http, data, this.state.authentication.token);
        }
    },
    removeDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        remove_detail(endpoint, res => {
            if (res.data.status === false) {
                sendErrorNotice('Không thể xóa bao hàng.')
            }
            else {
                sendSuccessNotice('Đã xóa bao hàng thành công.')
            }
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
};

// mutations
const mutations = {
    setBigPackageList(state, data) {
        state.all = data;
    },
    setDetail(state, item) {
        state.selected = item.data;
    },
    setTrancodeDetail(state, item) {
        state.trancodeDetail = item.data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
