import shop from '../../api/main_order';
import configs from '@/configs';
import { sendSuccessNotice, sendErrorNotice } from '@/commons/utils';
import {request_header, remove_detail} from '@/commons/hpod';
import Vue from 'vue';
import { refreshResource } from '../../commons/utils';

// initial state
const state = {
  loading: false,
  summaryOrders: {},
  all: [],
  selectedOrder: {
    Customer: {},
    PaymentHistory: [],
    OrderComment: [],
    Products: []
  }
};

// getters
const getters = {};

// actions
const actions = {
  getListOrders({ commit }, param) {
    commit("setLoading", { loading: true });
    shop.getListOrders(data => {
      commit('setListOrders', data);
      commit("setLoading", { loading: false });
    }, Vue.http, param, this.state.authentication.token);
  },
  getOrderDetail({ commit }, order_id) {
    commit("setLoading", { loading: true });
    shop.getOrderDetail(data => {
      commit('setOrderDetail', data);
      commit("setLoading", { loading: false });
    }, Vue.http, order_id, this.state.authentication.token);
  },
  updateOrderDetail({ commit, dispatch }, param) {
    commit("setLoading", { loading: true });
    shop.updateOrderDetail(res => {
      commit("setLoading", { loading: false });
      if (res.status === 202) {
        sendErrorNotice(res.reason)
      } 
      else if(res.data.status === 'fail'){
        sendErrorNotice('Không thể cập nhật đơn hàng.')
      }
      else {
        sendSuccessNotice('Cập nhật đơn hàng thành công.')
        dispatch("getOrderDetail", param.OrderID);
      }
    }, Vue.http, this.state.authentication.token, param);
  },
  updateProduct({ commit, dispatch }, param) {
    commit("setLoading", { loading: true });
    shop.updateProduct(res => {
      commit("setLoading", { loading: false });
      if(res.data.status === 'fail'){
        sendErrorNotice('Không thể cập nhật đơn hàng.')
      }
      else {
        sendSuccessNotice('Cập nhật đơn hàng thành công.')
        dispatch("getOrderDetail", param.OrderID);
      }
    }, Vue.http, this.state.authentication.token, param);
  },
  removeProduct({ commit, dispatch }, param) {
    commit("setLoading", { loading: true });
    remove_detail('products', res => {            
      commit("setLoading", { loading: false });
      dispatch("getOrderDetail", param.OrderID);
    }, Vue.http, param.id, this.state.authentication.token);
  },
  removeOrder({ commit, dispatch }, param) {
    commit("setLoading", { loading: true });
    remove_detail('main_order', res => {            
      commit("setLoading", { loading: false });
      refreshResource();
    }, Vue.http, param.id, this.state.authentication.token);
  },
  updateMultiple({ commit }, param) {
    commit("setLoading", { loading: true });
    shop.updateMultiple(res => {
      commit("setLoading", { loading: false });
      if(res.data.status === 'fail'){
        sendErrorNotice('Không thể cập nhật đơn hàng.')
      }
      else {
        sendSuccessNotice('Cập nhật đơn hàng thành công.')
        refreshResource();
      }
    }, Vue.http, this.state.authentication.token, param);
  },
  getProductList({commit}, order_id) {
    commit("setLoading", { loading: true });
    shop.getProductList(data => {
      commit('setProductList', data);
      commit("setLoading", { loading: false });
    }, Vue.http, order_id, this.state.authentication.token);
  },
  depositBlance({dispatch}, {ids, totalDeposit, param}) {
    const header = {
        headers: request_header(this.state.authentication.token)
    };
    var req = {
      'ids': ids,
      'totalDeposit': totalDeposit
    }
    Vue.http.post(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/main_order/deposit_blance/`, req, header).then(function (res) {
      dispatch('getListOrders', param);
    });
  },
  getSummaryOrders({commit}) {
    const header = {
        headers: request_header(this.state.authentication.token)
    };
    Vue.http.get(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/order_summary/`, header).then(function (res) {
      commit('setSummaryOrders', res);
    });
  },
  createNew({dispatch}, {data, param}) {
    const header = {
        headers: request_header(this.state.authentication.token)
    };
    Vue.http.post(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/main_order/create/`, data, header).then(function (res) {
      dispatch('getListOrders', param);
    });
  },
  createNewLink({dispatch}, {data, id}) {
    const header = {
        headers: request_header(this.state.authentication.token)
    };
    Vue.http.post(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/main_order/create_link/`, data, header).then(function (res) {
      dispatch("getOrderDetail", id);
    });
  },
  getListOrderDebts({ commit }, param) {
    commit("setLoading", { loading: true });
    shop.getListOrderDebts(data => {
      commit('setListOrders', data);
      commit("setLoading", { loading: false });
    }, Vue.http, param, this.state.authentication.token);
  },
};

// mutations
const mutations = {
  setListOrders(state, data) {
    state.all = data;
  },
  setOrderDetail(state, item) {
    state.selectedOrder = item.data;
  },
  setProductList(state, item) {
    state.selectedOrder.Products = item.data;
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  setSummaryOrders(state, item) {
    state.summaryOrders = item.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
