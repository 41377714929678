<template>
    <div>
        <v-autocomplete ref="input_data" clearable class="input-group--focused" append-icon='' v-if="isInputActive" :label="label" v-model="tempValue" :items="items" :item-text="item_text" :item-value="item_value" :readonly="readonly" :disabled="Number(disabled) === 1 || disabled === ''" @focus="isInputActive = true" :search-input.sync="searchInput" @blur="on_blur" @keyup.enter="press_enter">
            <template slot="no-data"><span style="visibility: hidden;"></span></template>
        </v-autocomplete>
        <v-text-field type="text" :rules="rules" v-else class="input-group--focused" :color="color" :label="label" v-model="tempValue" @blur="on_blur" @focus="isInputActive = true" :readonly="readonly" :disabled="Number(disabled) === 1 || disabled === ''" @keyup.enter="press_enter"/>
    </div>
</template>
<script>
export default {
    name: 'h-autocomplete-input',
    props: {
        value: String,
        label: String,
        disabled: Boolean,
        decimal: Number,
        showClass: String,
        readonly: Boolean,
        color: String,
        rules: Array,
        items: Array,
        item_text: String,
        item_value: String,
        searchTerm: String,
        autoEmit: { type: Boolean, default: true }
    },
    data: function() {
        return {
            isInputActive: false,
            tempValue: '',
            tempValueSearch: ''
        }
    },
    computed: {
        searchInput: {
            get () {
                return this.tempValueSearch
            },
            
            set (term) {
                if (this.tempValueSearch !== term && term != null) {
                    this.tempValueSearch = term;
                }
            }
        },
    },
    watch: {
        value() {
            this.tempValue = this.value;
            this.tempValueSearch = this.value;
        }, 
        isInputActive() {
            if(this.isInputActive) {
                this.$nextTick(() => this.$refs.input_data.focus());
            }
            else{ 
                this.tempValue = this.tempValueSearch;
            }
        }
    },
    methods: {
        press_enter(e) {
            if(!this.autoEmit) {
                this.tempValue = this.tempValueSearch;
                this.$emit('input', this.tempValue)
            }
            this.$emit('press_enter', e)
        },
        on_blur() {
            this.isInputActive = false;
            if(!this.autoEmit) {
                this.tempValue = this.tempValueSearch;
                this.$emit('input', this.tempValue)
            }
            else {
                this.$emit('on_blur')
            }
        }
    }
}
</script>
