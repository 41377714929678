import configs from '@/configs';
import {request_header} from '@/commons/hpod';

export default {
    getOrderCommentList(cb, $http, params, token) {
        const req = {
            headers: request_header(token)
        };
        $http.get(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/order_comment/?OrderID=${params.id}&OrderType=${params.type ? params.type : ''}`, req).then(function (res) {
            setTimeout(() => cb(res.data), 100);
        });
    },
    putOrderComment(cb, $http, data, token) {
        const header = {
            headers: request_header(token)
        };
        let req = _.cloneDeep(data);
        $http.put(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/order_comment/`, req, header).then(function (res) {
            setTimeout(() => cb(res.data), 100);
        });
    }
}
