import configs from '@/configs';
import {request_header, request_list} from '@/commons/hpod';

export default {
  getListOrders(cb, $http, param, token) {
    // request_list('main_order', cb, $http, param, token)
  },
  getListOrderDebts(cb, $http, param, token) {
    // request_list('main_order/debt', cb, $http, param, token)
  },
  getOrderDetail(cb, $http, order_id, token) {
    // const header = {
    //   headers: request_header(token)
    // };

    // $http.get(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/main_order/${order_id}`, header).then(function (res) {
    //   setTimeout(() => cb(res.data), 100);
    // });
  },
  updateOrderDetail(cb, $http, token, param) {
    const header = {
      headers: request_header(token)
    };
    let req = _.cloneDeep(param);
    delete req.OrderID;
    Object.keys(req).forEach((key) => (req[key] == null) && delete req[key]);

    $http.post(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/main_order/${param.OrderID}`, req, header).then(function (res) {
      setTimeout(() => cb(res.data), 100);
    });
  },
  updateMultiple(cb, $http, token, param) {
    const header = {
      headers: request_header(token)
    };
    let req = _.cloneDeep(param);
    Object.keys(req).forEach((key) => (req[key] == null) && delete req[key]);

    $http.post(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/main_order/`, req, header).then(function (res) {
      setTimeout(() => cb(res.data), 100);
    });
  },
  getProductList(cb, $http, order_id, token) {
    const header = {
      headers: request_header(token)
    };

    $http.get(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/products/?OrderID=${order_id}`, header).then(function (res) {
      setTimeout(() => cb(res.data), 100);
    });
  },
  updateProduct(cb, $http, token, param) {
    const header = {
      headers: request_header(token)
    };
    let req = _.cloneDeep(param);
    delete req.ID;
    Object.keys(req).forEach((key) => (req[key] == null) && delete req[key]);

    $http.post(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/products/${param.ID}`, req, header).then(function (res) {
      setTimeout(() => cb(res.data), 100);
    });
  }
}
