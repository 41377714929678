import configs from '@/configs';
import {request_header, request_list} from '@/commons/hpod';

export default {
    getPaymentHistoryList(cb, $http, param, token) {
        request_list('payment_histories', cb, $http, param, token)
    },
    getPaymentHistoryDetail(cb, $http, id, token) {
        const header = {
            headers: request_header(token)
        };

        $http.get(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/payment_histories/${id}`, header).then(function (res) {
            setTimeout(() => cb(res.data), 100);
        });
    }
}
