import user_api from '@/api/user';
import Vue from 'vue';

const state = {
    token: '',
    user: {},
    role: {},
    resData: {},
};

const getters = {};

const actions = {
    login({ commit, dispatch }, loginInfo) {   
        user_api.login(data => {
            commit('setToken', data);
            dispatch("roles/getList", null, {root:true} );
        }, Vue.http, loginInfo.username, loginInfo.password);
    },
    // changePass({ commit },changePassInfo) {   
    //     authenAccount.changePass(Change => {
    //         commit('setChange', Change);        
    //     }, Vue.http, this.state.authen.token, changePassInfo.current_password, changePassInfo.new_password, changePassInfo.retype_new_password, this.state.authen.user.email);
    // },
    // forgotPassword({ commit }, email) {   
    //     authenAccount.forgotPass(data => {
    //         commit('forgotPass', data);        
    //     }, Vue.http, email);
    // }
    
};

const mutations = {
    setToken(state, data) {
        state.resData = data;
        if(data.status_code == 400){
            return;
        }

        state.token = data.token;
        state.user = data.user;
        localStorage.setItem('jwt', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));  
    },
    setRole(state, data) {
        state.role = data;
    },
    // setChange(state, Change) {
    //     state.resData = Change;
    // },
    // forgotPass(state, data) {
    //     state.resData = data;
    // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
