import { request_detail, update_detail, create_detail } from '@/commons/hpod';
import { sendSuccessNotice, sendErrorNotice } from '@/commons/utils';
import Vue from 'vue';
const endpoint = 'configuration'
const viewTitle = 'cấu hình chung'

// initial state
const state = {
    loading: false,
    selected: {
        ID: 0
    }
};

// getters
const getters = {};

// actions
const actions = {
    getDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        request_detail(endpoint, data => {
            commit('setDetail', data);
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    setDetail({ commit }, {data, id}) {
        if(id > 0) {
            update_detail(endpoint, res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice(`Không thể cập nhật ${viewTitle}.`)
                }
                else {
                    sendSuccessNotice(`Cập nhật ${viewTitle} thành công.`)
                }
            }, Vue.http, data, id, this.state.authentication.token);
        }
        else {
            delete data.ID;
            create_detail(endpoint, res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice(`Không thể thêm mới ${viewTitle}.`)
                }
                else {
                    sendSuccessNotice(`Thêm mới ${viewTitle} thành công.`);
                }
            }, Vue.http, data, this.state.authentication.token);
        }
    }
};

// mutations
const mutations = {
    setDetail(state, item) {
        state.selected = item.data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
