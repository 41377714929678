import configs from '@/configs'
import _ from "lodash";
import { currency } from "@/commons/currency";
import { sendErrorNotice, refreshResource } from "@/commons/utils";
import moment from "moment"

export function request_header(token) {
  return {
    "Authorization": `Bearer ${token}`,
    "Content-Type": "application/json",
    "accept": "application/json",
  };
}

export function request_payload(param) {
  let query = '';
  let q_params = [];
  if(param && (param.pagination || param.qObject || param.filter || param.quickFilter)) {
    const page = param.pagination && param.pagination.page ? param.pagination.page : 1
    const rowsPerPage = param.pagination && param.pagination.itemsPerPage ? param.pagination.itemsPerPage : 1000000
    const sortBy = param.pagination ? param.pagination.sortBy[0] : 'ID'
    const descending = param.pagination ? param.pagination.sortDesc[0] : 1
    const qObject = param.qObject ? Buffer.from(JSON.stringify({ qObject: param.qObject })).toString("base64") : '';
    const filter = param.filter && param.filter.length > 0 ? Buffer.from(JSON.stringify({ filter: param.filter })).toString("base64") : '';
    const quickFilter = param.quickFilter && param.quickFilter.length > 0 ? Buffer.from(JSON.stringify({ filter: param.quickFilter })).toString("base64") : '';
    q_params.push(`filter=${encodeURIComponent(filter)}&qObject=${encodeURIComponent(qObject)}&page=${page}&rowsPerPage=${rowsPerPage}&sortBy=${sortBy}&descending=${descending}&q=${encodeURIComponent(quickFilter)}`)
  }
  
  if(param) {
    var fields = []
    Object.keys(param).forEach((key) => {
      if(!['pagination', 'qObject', 'filter', 'quickFilter'].includes(key)) {
        fields.push(`${key}=${param[key] == null ? '' : param[key]}`)
      }
    });
    q_params.push(`${fields.join('&')}`);
  }
  query = `?${q_params.join('&')}`;
  return query;
}

export function request_list(endpoint, cb, $http, param, token) {
  const req = {
    headers: request_header(token)
  };
  
  const query = request_payload(param);

  $http.get(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/${endpoint}/${query}`, req).then(function (res) {
    setTimeout(() => cb(res.data), 100);
  },
  function (err) {
    if(err.status == 401) {
      window.getApp.$emit("APP_AUTH_FAILED");
    }
  });
}

export function export_list(endpoint, cb, $http, param, token) {
  const req = {
    headers: request_header(token),
    responseType: 'blob'
  };
  
  const query = request_payload(param)

  $http.get(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/${endpoint}/export/${query}`, req).then(function (res) {
    setTimeout(() => cb(res), 100);
  });
}

export function import_list(endpoint, cb, $http, param, token, func) {
  const header = {
    headers: request_header(token)
  };
  header.headers['Content-Type'] = 'multipart/form-data';
  func = func ? func : 'import'

  $http.post(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/${endpoint}/${func}/`, param, header).then(function (res) {
    setTimeout(() => cb(res), 100);
  });
}

export function request_detail(endpoint, cb, $http, id, token, param) {
  const header = {
    headers: request_header(token)
  };
  let query = '';
  if(param) {
    if(param.pagination) {
      const page = param.pagination ? param.pagination.page : 1
      const rowsPerPage = param.pagination ? param.pagination.itemsPerPage : 1000000
      const sortBy = param.pagination ? param.pagination.sortBy[0] : 'ID'
      const descending = param.pagination ? param.pagination.sortDesc[0] : 1
      query = `?page=${page}&rowsPerPage=${rowsPerPage}&sortBy=${sortBy}&descending=${descending}`;
      delete param.pagination;
    }
    if(param.filter) {
      const filter = param.filter && param.filter.length > 0 ? Buffer.from(JSON.stringify({ filter: param.filter })).toString("base64") : '';
      query = query + (query == '' ? '?' : '&') + `filter=${filter}`;
      delete param.filter;
    }
    if(param.quickFilter) {
      const quickFilter = param.quickFilter && param.quickFilter.length > 0 ? Buffer.from(JSON.stringify({ filter: param.quickFilter })).toString("base64") : ''; 
      query = query + (query == '' ? '?' : '&') + `q=${quickFilter}`;
      delete param.quickFilter;
    }
    var fields = []
    Object.keys(param).forEach((key) => fields.push(`${key}=${param[key]}`));
    query = query == '' ? `?${fields.join('&')}` : `${query}&${fields.join('&')}`;
  }
  $http.get(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/${endpoint}/${id}${query}`, header).then(function (res) {
    setTimeout(() => cb(res.data), 100);
  },
  function (err) {
    if(err.status == 401) {
      window.getApp.$emit("APP_AUTH_FAILED");
    }
  });
}

export function update_detail(endpoint, cb, $http, param, pId, token) {
  const header = {
    headers: request_header(token)
  };
  let req = _.cloneDeep(param);
  Object.keys(req).forEach((key) => (req[key] == null) && delete req[key]);
  Object.keys(req).forEach((key) => {if(typeof(req[key]) == 'boolean'){req[key] = Number(req[key])}});
  let path = pId == 0 ? `${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/${endpoint}/` : `${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/${endpoint}/${pId}`;
  $http.post(path, req, header).then(function (res) {
    setTimeout(() => cb(res.data), 100);
  },
  function (err) {
    if(err.status == 401) {
      window.getApp.$emit("APP_AUTH_FAILED");
    }
    else if(err.status == 406) {
      sendErrorNotice(err.data.reason);
      refreshResource();
    }
  });
}

export function remove_detail(endpoint, cb, $http, id, token) {
  const header = {
    headers: request_header(token)
  };
  $http.delete(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/${endpoint}/${id}`, header).then(function (res) {
    setTimeout(() => cb(res.data), 100);
  },
  function (err) {
    if(err.status == 401) {
      window.getApp.$emit("APP_AUTH_FAILED");
    }
    else if(err.status == 406) {
      sendErrorNotice(err.data.reason);
      refreshResource();
    }
  });
}

export function create_detail(endpoint, cb, $http, param, token) {
  const header = {
    headers: request_header(token)
  };
  let req = _.cloneDeep(param);
  delete req['ID'];
  Object.keys(req).forEach((key) => (req[key] == null) && delete req[key]);
  Object.keys(req).forEach((key) => {if(typeof(req[key]) == 'boolean'){req[key] = Number(req[key])}});
  $http.put(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/${endpoint}/`, req, header).then(function (res) {
    setTimeout(() => cb(res.data), 100);
  },
  function (err) {
    if(err.status == 401) {
      window.getApp.$emit("APP_AUTH_FAILED");
    }
    else if(err.status == 406) {
      sendErrorNotice(err.data.reason);
      refreshResource();
    }
  });
}

export function comment_type(value) {
  const cmtType = configs.COMMENT_TYPE.find(obj => obj.id === value);
  return cmtType ? cmtType.name : '';
}

export function comment_type_color(value) {
  const cmtType = configs.COMMENT_TYPE.find(obj => obj.id === value);
  return cmtType ? cmtType.color : '#e6c87f';
}

export function comment_avatar(value) {
  if (value !== null) {
    return '/android-icon-96x96.png';
  }
  return '/static/avatar/no_avatar.png';
}

export function comment_color(value) {
  if (value) {
    return '#ff0000';
  }
  return '#0000ff';
}

export function payment_type(value) {
  switch (value) {
    case 2:
    case 3:
      return "Đặt cọc";
    case 12:
      return "Hoàn tiền"
    default:
      return "Thanh toán";
  }
}

export function payment_type_color(value) {
  switch (value) {
    case 2:
      return "#e6c87f";
    case 3:
      return "#ffff00";
    case 12:
      return "#ff0000"
    default:
      return "#0000ff";
  }
}

export function complain_type(value) {
  switch (value) {
    case 1:
      return "Chờ xử lý";
    case 2:
      return "Đang xử lý";
    case 3:
      return "Đã xử lý"
    default:
      return "Đã hủy";
  }
}

export function complain_type_color(value) {
  switch (value) {
    case 1:
      return "#ff0000";
    case 2:
      return "#797960";
    case 3:
      return "#0000ff"
    default:
      return "#e6c87f";
  }
}

export function trade_type(value) {
  const cmtType = configs.TRADE_TYPE.find(obj => obj.value === value);
  return cmtType ? cmtType.name : '';
}

export function print_transaction(weight, weight_v2, length, width, height, transactionCode, customerName, orderId, numberOfCode) {
  var mywindow = window.open("", "PRINT");

  mywindow.document.write("<html><head><title></title>");
  mywindow.document.write(
    "<style>.row {display: flex;}.flex {flex: 1 1 auto;}.flex.lg6 {flex-basis: 50%;flex-grow: 0;max-width: 50%;}"
  );
  mywindow.document.write(
    ".flex.lg12 {flex-basis: 100%;flex-grow: 0;max-width: 100%;}.title {font-size: 24px; font-weight: bold; text-align: center; margin: 5px 0 3px 0 !important;}"
  );
  mywindow.document.write(
    ".sub-title {font-size: 16px; font-weight: bold; margin: 0 0 8px 0 !important;}.l-text {text-align: left;}.r-text {text-align: right;}.note-text {font-size: 18px; font-weight: bold; font-style: italic; text-align: center;}"
  );
  mywindow.document.write(
    "</style></head><body style='height:8cm; width:9cm; margin-left: 5px; margin-right:5px;'>"
  );
  mywindow.document.write("<div class='layout wrap'>");
  mywindow.document.write(
    `<div class='flex lg12'><p class='title'>${customerName}</p></div>`
  );
  mywindow.document.write(
    `<div class='flex lg12'><hr class='v-divider theme--light'></div>`
  );
  mywindow.document.write(
    `<div class='flex lg12'><p class='title'>${orderId}</p></div>`
  );
  mywindow.document.write(
    `<div class='row'><div class='flex lg6'><p class='sub-title l-text'>Số kiện</p></div><div class='flex lg6'><p class='sub-title r-text'>${numberOfCode}</p></div></div>`
  );
  mywindow.document.write(
    `<div class='row'><div class='flex lg6'><p class='sub-title l-text'>Khối lượng</p></div><div class='flex lg6'><p class='sub-title r-text'>${weight > weight_v2 ? weight : weight_v2} kg</p></div></div>`
  );
  if(weight_v2 > weight) {
    mywindow.document.write(
      `<div class='row'><div class='flex lg6'><p class='sub-title l-text'>Kích thước (cm)</p></div><div class='flex lg6'><p class='sub-title r-text'>${length} x ${width} x ${height}</p></div></div>`
    );
  }
  mywindow.document.write(
    `<div class='row'><div class='flex lg6'><p class='sub-title l-text'>Mã bill</p></div><div class='flex lg6'><p class='sub-title r-text'>${transactionCode}</p></div></div>`
  );
  mywindow.document.write(
    `<div class='row'><div class='flex lg6'><p class='sub-title l-text'>Ngày tạo</p></div><div class='flex lg6'><p class='sub-title r-text'>${moment(
      new Date()
    ).format("DD-MM-YYYY")}</p></div></div>`
  );
  mywindow.document.write(
    `<div class='flex lg12'><p class='note-text'>Lưu ý</p></div>`
  );
  mywindow.document.write(
    `<div class='flex lg12'><p class='note-text'>Qúy khách vui lòng giữ lại mã bill Trung Quốc để sử dụng trong trường hợp khiếu nại với shop.</p></div>`
  );
  mywindow.document.write(`<hr class='v-divider theme--light'>`);
  mywindow.document.write(
    `<div class='flex lg12'><p class='note-text'>${configs.SYSTEM_CONFIG.domain}</p></div>`
  );
  mywindow.document.write("</div>");
  mywindow.document.write("</body></html>");

  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/
  mywindow.print();
  mywindow.close();
  return true;
}

export function print_transaction_v2(item, staffName, render = false, subItem = null, is_all = false) {
  let barcodeid = 'vncode128';
  let qrcodeid = 'qrcode';
  let print_body = '';
  if(is_all && item.Packages) {
    let packages = JSON.parse(item.Packages);
    for (let i = 0; i < packages.length; i++) {
      const subItem = packages[i];
      print_body = print_body + `<div class='layout wrap pagebreak'>
                                  <table>
                                    <tr>
                                        <td class='title m-text' colspan='6'>Customer Receipt</td>
                                    </tr>
                                    <tr>
                                        <td class='m-text' colspan='6'>
                                          <svg id="${barcodeid}${i}"></svg>
                                          <br/>
                                          <span>Mã đơn hàng: ${subItem ? subItem.TransactionCode : item.TransactionCode}</span>
                                          <br/>
                                          <span>Mã vận đơn: ${subItem ? subItem.TransactionCodeVN : item.TransactionCodeVN}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class='sub-title' colspan='6'>From: ${subItem.FakeShopName}</br>${subItem.FakeShopAddress}</td>
                                    </tr>
                                    <tr>
                                        <td class='sub-title' colspan='6'>To: ${subItem.FakeCustomerName} | +${subItem.FakeCustomerPhone}</td>
                                    </tr>
                                    <tr>
                                        <td class='body-text' colspan='6'>${subItem.FakeCustomerAddress}</td>
                                    </tr>
                                    <tr>
                                        <td class='body-text m-text' colspan='3'>${staffName}</td>
                                        <td class='sub-title m-text' colspan='3'>${item.Username}</td>
                                    </tr>
                                    <tr>
                                        <td class='m-text' rowspan='2' colspan='3'><div id="${qrcodeid}${i}" style="margin-left: 80px"></div></td>
                                        <td class='body-text m-text' colspan='3'>Trọng lượng</td>
                                    </tr>
                                    <tr>
                                        <td class='sub-title m-text' colspan='3'>${ currency(subItem ? subItem.Weight : item.ChinaWeight, '', 2)}kg</td>
                                    </tr>
                                    <tr>
                                        <td class='body-text m-text' colspan='6'>${moment(subItem.FakePrintDate).format("DD/MM/YYYY hh:mm A")}</td>
                                    </tr>
                                    <tr>
                                        <td class='body-text m-text' colspan='6'>Hàng hóa thông quan điện tử</td>
                                    </tr>
                                    <tr>
                                        <td class='sub-title m-text' colspan='6'>Không mở gói hàng, không đồng kiểm</td>
                                    </tr> 
                                    <tr>
                                        <td class='body-text m-text' colspan='6'>${item.ProductName || ''}</td>
                                    </tr>
                                  </table>
                              </div>
                              <script>
                                new QRCode(document.getElementById("${qrcodeid}${i}"), {
                                  text: "${subItem ? subItem.TransactionCode : item.TransactionCode}",
                                  width: 96,
                                  height: 96,
                                  colorDark : "#000000",
                                  colorLight : "#ffffff",
                                  correctLevel : QRCode.CorrectLevel.H
                                });
                                JsBarcode("#${barcodeid}${i}", "${subItem ? subItem.TransactionCode : item.TransactionCode}", {format: "code128", fontSize: 14, height: 50, width: 1.5, textMargin: 2, displayValue: false});
                              </script>`;
    }
  }
  else {
    print_body = `<div class='layout wrap'>
                      <table>
                        <tr>
                            <td class='title m-text' colspan='6'>Customer Receipt</td>
                        </tr>
                        <tr>
                            <td class='m-text' colspan='6'>
                              <svg id="vncode128"></svg>
                              <br/>
                              <span>Mã đơn hàng: ${subItem ? subItem.TransactionCode : item.TransactionCode}</span>
                              <br/>
                              <span>Mã vận đơn: ${subItem ? subItem.TransactionCodeVN : item.TransactionCodeVN}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class='sub-title' colspan='6'>From: ${item.FakeShopName}</br>${item.FakeShopAddress}</td>
                        </tr>
                        <tr>
                            <td class='sub-title' colspan='6'>To: ${item.FakeCustomerName} | +${item.FakeCustomerPhone}</td>
                        </tr>
                        <tr>
                            <td class='body-text' colspan='6'>${item.FakeCustomerAddress}</td>
                        </tr>
                        <tr>
                            <td class='body-text m-text' colspan='3'>${staffName}</td>
                            <td class='sub-title m-text' colspan='3'>${item.Username}</td>
                        </tr>
                        <tr>
                            <td class='m-text' rowspan='2' colspan='3'><div id="qrcode" style="margin-left: 80px"></div></td>
                            <td class='body-text m-text' colspan='3'>Trọng lượng</td>
                        </tr>
                        <tr>
                            <td class='sub-title m-text' colspan='3'>${ currency(subItem ? subItem.Weight : item.ChinaWeight, '', 2)}kg</td>
                        </tr>
                        <tr>
                            <td class='body-text m-text' colspan='6'>${moment(item.FakePrintDate).format("DD/MM/YYYY hh:mm A")}</td>
                        </tr>
                        <tr>
                            <td class='body-text m-text' colspan='6'>Hàng hóa thông quan điện tử</td>
                        </tr>
                        <tr>
                            <td class='sub-title m-text' colspan='6'>Không mở gói hàng, không đồng kiểm</td>
                        </tr> 
                        <tr>
                            <td class='body-text m-text' colspan='6'>${item.ProductName || ''}</td>
                        </tr>
                      </table>
                  </div>
                  <script>
                    new QRCode(document.getElementById("qrcode"), {
                      text: "${subItem ? subItem.TransactionCode : item.TransactionCode}",
                      width: 96,
                      height: 96,
                      colorDark : "#000000",
                      colorLight : "#ffffff",
                      correctLevel : QRCode.CorrectLevel.H
                    });
                    JsBarcode("#vncode128", "${subItem ? subItem.TransactionCode : item.TransactionCode}", {format: "code128", fontSize: 14, height: 50, width: 1.5, textMargin: 2, displayValue: false});
                  </script>`;
  }
  const frame_printing = `<html>
  <head>
      <title></title>
      <script src="/static/js/JsBarcode.all.min.js"></script>
      <script src="/static/js/qrcode.min.js"></script>
      <style>
          .row { display: flex; } .flex { flex: 1 1 auto; } .flex.lg6 { flex-basis: 50%; flex-grow: 0; max-width: 50%; } .flex.lg8 { flex-basis: 66%; flex-grow: 0; max-width: 66%; } .flex.lg4 { flex-basis: 34%; flex-grow: 0; max-width: 34%; } .flex.lg3 { flex-basis: 25%; flex-grow: 0; max-width: 25%; } .flex.lg12 { flex-basis: 100%; flex-grow: 0; max-width: 100%; } .title { font-size: 18px; font-weight: bold; text-align: center; } .sub-title { font-size: 17px; font-weight: bold; } .l-text { text-align: left; } .r-text { text-align: right; } .m-text { text-align: center; } .note-text { font-size: 16px; font-weight: bold; font-style: italic; text-align: center; } .body-text { font-size: 16px; } table { border-collapse: collapse; width: 100%; } td, th { border: 1px solid #4a4a4a; text-align: left; padding: 1px; } hr { border: 0.5px solid #dddddd; } tr, td, th { page-break-inside: avoid !important; height: 40px; word-wrap: break-word;max-width: 100mm;} @media print { @page { size: auto; margin: 2mm 2mm 2mm 2mm; } html { background-color: #FFFFFF; margin: 0px; } body { margin: 0mm 0mm 0mm 0mm; } }
          #background{
            position:absolute;
            z-index:-1;
            background:white;
            display:block;
            min-height:10%; 
            min-width:10%;
          }
          #bg-text
          {
            color:#e6e6e6;
            font-size:50px;
            font-weight:700;
            transform:rotate(330deg);
            -webkit-transform:rotate(330deg);
            margin-left: 30px;
            margin-top: 180px;
          }
          @media print {
            .pagebreak { page-break-before: always; } /* page-break-after works, as well */
          }
      </style>
  </head>
  
  <body style='width:100mm;font-family: Roboto,sans-serif;'>
      ${print_body}
  </body>
  </html>`;
  if(render) {
    return frame_printing;
  }
  var mywindow = window.open("", "PRINT");
  mywindow.document.write(frame_printing);
  mywindow.document.close(); // necessary for IE >= 10
  // mywindow.focus(); // necessary for IE >= 10*/
  // mywindow.print();
  // mywindow.close();
  return true;
}

export function print_bigpackage(item, render = false) {
  const frame_printing = `<html>
  <head>
      <title></title>
      <script src="/static/js/JsBarcode.all.min.js"></script>
      <style>
          .row { display: flex; } .flex { flex: 1 1 auto; } .flex.lg6 { flex-basis: 50%; flex-grow: 0; max-width: 50%; } .flex.lg8 { flex-basis: 66%; flex-grow: 0; max-width: 66%; } .flex.lg4 { flex-basis: 34%; flex-grow: 0; max-width: 34%; } .flex.lg3 { flex-basis: 25%; flex-grow: 0; max-width: 25%; } .flex.lg12 { flex-basis: 100%; flex-grow: 0; max-width: 100%; } .title { font-size: 20px; font-weight: bold; text-align: center; } .sub-title { font-size: 19px; font-weight: bold; } .l-text { text-align: left; } .r-text { text-align: right; } .m-text { text-align: center; } .note-text { font-size: 18px; font-weight: bold; font-style: italic; text-align: center; } .body-text { font-size: 18px; } table { border: 1px solid #dddddd; border-collapse: collapse; width: 100%; } td, th { border: 0px solid #dddddd; text-align: left; padding: 1px; } hr { border: 0px solid #dddddd; } tr, td, th { page-break-inside: avoid !important; height: 40px;} @media print { @page { size: auto; margin: 2mm 2mm 2mm 2mm; } html { background-color: #FFFFFF; margin: 0px; } body { margin: 0mm 0mm 0mm 0mm; } }
      </style>
  </head>
  
  <body style='width:100mm;font-family: Roboto,sans-serif;'>
      <div class='layout wrap'>
          <table>
            <tr>
                <td>${item.CreatedBy} - ${item.ReceivePlaceName}</td>
            </tr>
            <tr>
                <td class='title m-text'>VCN</td>
            </tr>
            <tr>
                <td class='m-text'><svg id="code128"></svg></td>
            </tr>
            <tr>
                <td class='m-text'>Số kiện: <b>${item.SplitNumber}</b></td>
            </tr>
            <tr>
                <td class='m-text'>Trọng lượng bao: <b>${currency(item.Weight,'',1)}</b></td>
            </tr>
          </table>
      </div>
      <script>
          JsBarcode("#code128", "${item.PackageCode}", {format: "code128", fontSize: 14, height: 50, width: 1.5, textMargin: 2, displayValue: true});
      </script>
  </body>
  </html>`;

  if(render) {
    return frame_printing;
  }

  var mywindow = window.open("", "PRINT");

  mywindow.document.write(frame_printing);
  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/
  mywindow.print();
  mywindow.close();
  return true;
}

function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

export function print_export_receipt_order(item, packages, render = false) {
  let html_packages = ''
  for (let i = 0; i < packages.length; i++) {
    const p = packages[i];
    html_packages = html_packages + `<tr>
                        <td class='body-text m-text'>${i+1}</td>
                        <td class='body-text'>${p.TransactionCode}</td>
                        <td class='body-text r-text'>${p.OrderID}</td>
                        <td class='body-text m-text'></td>
                        <td class='body-text r-text'>1</td>
                        <td class='body-text r-text'>${currency(p.Weight,'',1)}</td>
                        <td class='body-text r-text'>${currency(p.Volume,'',1)}</td>
                        <td class='body-text m-text'></td>
                      </tr>`
  }

  const print_content = `<html>
  <head>
      <title></title>
      <style>
          .row { display: flex; } .flex { flex: 1 1 auto; } .flex.lg6 { flex-basis: 50%; flex-grow: 0; max-width: 50%; } .flex.lg8 { flex-basis: 66%; flex-grow: 0; max-width: 66%; } .flex.lg4 { flex-basis: 34%; flex-grow: 0; max-width: 34%; } .flex.lg3 { flex-basis: 25%; flex-grow: 0; max-width: 25%; } .flex.lg12 { flex-basis: 100%; flex-grow: 0; max-width: 100%; } .title { font-size: 16px; font-weight: bold; text-align: center; } .sub-title { font-size: 11px; font-weight: bold; } .l-text { text-align: left; } .r-text { text-align: right; } .m-text { text-align: center; } .note-text { font-size: 11px; font-weight: bold; font-style: italic; text-align: center; } .body-text { font-size: 11px; } table { border-collapse: collapse; width: 100%; } td, th { border: 1px solid #4a4a4a; text-align: left; padding: 1px; } hr { border: 0.5px solid #dddddd; } tr, td, th { page-break-inside: avoid !important; height: 30px; padding: 5px} @media print { @page { size: auto; margin: 2mm 2mm 2mm 2mm; } html { background-color: #FFFFFF; margin: 0px; } body { margin: 0mm 0mm 0mm 0mm; } } .no-boder {border: 0px !important;} .quantity {width: 30px;} .width-volumn {width: 50px;}
      </style>
  </head>
  
  <body style='width:105mm;font-family: Roboto,sans-serif;'>
      <div class='layout wrap'>
          <table>
              <tr>
                <td class='no-boder sub-title m-text'>TEE-LOGISTICS<br/>XNK-VẬN CHUYỂN HÀNG HÓA</td>
                <td class='no-boder sub-title m-text'>PHIẾU XUẤT KHO</td>
              </tr>	
              <tr>
                <td class='no-boder sub-title m-text'>039.739.1688 - 085.285.1688</td>
                <td class='no-boder body-text m-text'><i>Ngày ${moment().date()} tháng ${moment().month()+1} năm ${moment().year()}</i></td>
              </tr>	
              <tr>        
                <td class='no-boder body-text r-text' colspan='2'>Số phiếu: ${ pad(item.ID, 10) }</td>
              </tr>
              <tr>        
                <td class='no-boder body-text'>Người nhận hàng: ${item.Receiver}</td>
                <td class='no-boder body-text'>Điện thoại: ${item.Phone}</td>
              </tr>
              <tr>        
                <td class='no-boder body-text' colspan='2'>Địa chỉ nhận hàng: ${item.Address}</td>
              </tr>
            </table>
            <table>
              <tr>
                  <td class='sub-title m-text' rowspan='2'>STT</td>            
                  <td class='sub-title m-text' rowspan='2'>Tên, mã hàng hóa</td>            
                  <td class='sub-title m-text' rowspan='2'>Mã đơn hàng</td>
                  <td class='sub-title m-text' rowspan='2'>ĐVT</td>            
                  <td class='sub-title m-text quantity' rowspan='2'>Số lượng</td>            
                  <td class='sub-title m-text' colspan='2'>Khối lượng</td>            
                  <td class='sub-title m-text' rowspan='2'>Ghi chú</td>
              </tr>
              <tr>                
                  <td class='sub-title r-text width-volumn'>KG</td>
                  <td class='sub-title r-text width-volumn'>Kích thước</td>
              </tr>
              ${html_packages}
              <tr>
                <td class='sub-title m-text' colspan='3'>Tổng số</td>
                <td class='sub-title m-text'></td>
                <td class='sub-title r-text'>${packages.length}</td>
                <td class='sub-title r-text'>${currency(_.sumBy(packages, 'Weight'),'',1)}</td>
                <td class='sub-title r-text'>${currency(_.sumBy(packages, 'Volume'),'',1)}</td>
                <td class='sub-title m-text'></td>
              </tr> 
              <tr>
                        <td class='no-boder body-text' colspan='7'><i>Quý khách vui lòng kiểm tra lại mã hàng, số lượng, khối lượng khi nhận hàng!</i></td>
                    </tr> 
              <tr>
                        <td class='no-boder body-text' colspan='7'>Tổng số<i>(bằng chữ)</i></td>
                    </tr> 
              <tr>
                        <td class='no-boder body-text' colspan='7'>Chứng từ, giấy tờ kèm theo:</td>
                    </tr> 
              <tr>
                        <td class='no-boder body-text r-text' colspan='7'><i>Ngày ${moment().date()} tháng ${moment().month()+1} năm ${moment().year()}</i></td>
                    </tr> 
              <tr>
                        <td class='no-boder body-text m-text' colspan='2'>Người lập phiếu<br/><i>(Ký, họ tên)</i></td>
                        <td class='no-boder body-text m-text' colspan='2'>Thủ kho<br/><i>(Ký, họ tên)</i></td>
                        <td class='no-boder body-text m-text' colspan='1'>Lái xe<br/><i>(Ký, họ tên)</i></td>
                        <td class='no-boder body-text m-text' colspan='2'>Người nhận hàng<br/><i>(Ký, họ tên)</i></td>
                    </tr> 
                    <tr>
                        <td class='no-boder body-text m-text' colspan='2'></td>
                        <td class='no-boder body-text m-text' colspan='2'></td>
                        <td class='no-boder body-text m-text' colspan='1'></td>
                        <td class='no-boder body-text m-text' colspan='2'></td>
                    </tr> 
                    <tr>
                        <td class='no-boder body-text m-text' colspan='2'></td>
                        <td class='no-boder body-text m-text' colspan='2'></td>
                        <td class='no-boder body-text m-text' colspan='1'></td>
                        <td class='no-boder body-text m-text' colspan='2'></td>
                    </tr> 
                    <tr>
                      <td class='no-boder body-text m-text' colspan='2'>${item.Voter}</td>
                      <td class='no-boder body-text m-text' colspan='2'></td>
                      <td class='no-boder body-text m-text' colspan='1'></td>
                      <td class='no-boder body-text m-text' colspan='2'></td>
                  </tr> 
                </table>
            </div>
        </body>
  </html>`;
  if(render) {
    return print_content;
  }
  var mywindow = window.open("", "PRINT");
  mywindow.document.write(print_content);
  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/
  mywindow.print();
  // mywindow.close();
  return true;
}

export function print_export_receipt_deposit(item, packages, render = false) {
  let html_packages = ''
  for (let i = 0; i < packages.length; i++) {
    const p = packages[i];
    html_packages = html_packages + `<tr>
                        <td class='body-text m-text'>${i+1}</td>
                        <td class='body-text'>${p.TransactionCode}</td>
                        <td class='body-text r-text quantity'>${currency(p.RealNumberOfPack)}</td>
                        <td class='body-text r-text'>${currency(p.TotalWeight,'',2)}</td>
                        <td class='body-text r-text'>${currency(p.TotalVolume,'',3)}</td>
                        <td class='body-text m-text'></td>
                      </tr>`
  }

  const print_content = `<html>
  <head>
      <title></title>
      <script src="/static/js/JsBarcode.all.min.js"></script>
      <style>
          .row { display: flex; } .flex { flex: 1 1 auto; } .flex.lg6 { flex-basis: 50%; flex-grow: 0; max-width: 50%; } .flex.lg8 { flex-basis: 66%; flex-grow: 0; max-width: 66%; } .flex.lg4 { flex-basis: 34%; flex-grow: 0; max-width: 34%; } 
          .flex.lg3 { flex-basis: 25%; flex-grow: 0; max-width: 25%; } .flex.lg12 { flex-basis: 100%; flex-grow: 0; max-width: 100%; } .customer_code { font-size: 42px; font-weight: bold; text-align: center; } 
          .title { font-size: 16px; font-weight: bold; text-align: center; } .sub-title { font-size: 11px; font-weight: bold; } .sub-title-2 { font-size: 9px; font-weight: bold; } .l-text { text-align: left; } 
          .r-text { text-align: right; } .m-text { text-align: center; } .note-text { font-size: 11px; font-weight: bold; font-style: italic; text-align: center; } .body-text { font-size: 11px; } 
          .body-text-2 { font-size: 9px; } table { border-collapse: collapse; width: 100%; } td, th { border: 1px solid #4a4a4a; text-align: left; padding: 1px; } hr { border: 0.5px solid #dddddd; } tr, td, th { page-break-inside: avoid !important; height: 30px; padding: 5px} 
          @media print { @page { size: auto; margin: 2mm 2mm 2mm 2mm; } html { background-color: #FFFFFF; margin: 0px; } body { margin: 0mm 0mm 0mm 0mm; } } .no-boder {border: 0px !important;} .quantity {width: 30px;} .width-volumn {width: 50px;}
      </style>
  </head>
  
  <body style='width:105mm;font-family: Roboto,sans-serif;'>
      <div class='layout wrap'>
          <table>
              <tr>
                <td class='no-boder sub-title-2 m-text'>TEE-LOGISTICS<br/>XNK-VẬN CHUYỂN HÀNG HÓA</td>
                <td class='no-boder sub-title-2 m-text'>PHIẾU XUẤT KHO</td>
              </tr>	
              <tr>
                <td class='no-boder sub-title-2 m-text'>090.81.82.700 - 090.81.82.700</td>
                <td class='no-boder body-text-2 m-text'><i>Ngày ${moment().date()} tháng ${moment().month()+1} năm ${moment().year()}</i></td>
              </tr>	
              <tr>
                <td class='no-boder sub-title m-text'></td>
                <td class='no-boder body-text-2 m-text'>Số phiếu: ${ item.ID }</td>
              </tr>
              <tr>
                  <td class='no-boder customer_code' colspan='2'>${ item.Username }</td>
              </tr>
              <tr>
                  <td class='no-boder m-text' colspan='2'><svg id="code128"></svg></td>
              </tr>
              <tr>        
                <td class='no-boder body-text'>Người nhận hàng: ${item.Receiver}</td>
                <td class='no-boder body-text'>Điện thoại: ${item.Phone}</td>
              </tr>
              <tr>        
                <td class='no-boder body-text' colspan='2'>Địa chỉ nhận hàng: ${item.Address}</td>
              </tr>
            </table>
            <table>
              <tr>
                  <td class='sub-title m-text' rowspan='2'>STT</td>            
                  <td class='sub-title m-text' rowspan='2'>Tên, mã hàng hóa</td>
                  <td class='sub-title m-text quantity' rowspan='2'>Số lượng</td>            
                  <td class='sub-title m-text' colspan='2'>Khối lượng</td>            
                  <td class='sub-title m-text' rowspan='2'>Ghi chú</td>
              </tr>
              <tr>                
                  <td class='sub-title r-text width-volumn'>KG</td>
                  <td class='sub-title r-text width-volumn'>Kích thước</td>
              </tr>
              <tr>
                <td class='sub-title m-text' colspan='2'>Tổng số</td>
                <td class='sub-title r-text quantity'>${currency(_.sumBy(packages, 'RealNumberOfPack'))}</td>
                <td class='sub-title r-text'>${currency(_.sumBy(packages, 'TotalWeight'),'',2)}</td>
                <td class='sub-title r-text'>${currency(_.sumBy(packages, 'TotalVolume'),'',3)}</td>
                <td class='sub-title m-text'>${item.NoteForCustomer ? item.NoteForCustomer : ''}</td>
              </tr>
              ${html_packages}
              </table>
              <table>
              <tr>
                        <td class='no-boder body-text' colspan='6'><i>Quý khách vui lòng kiểm tra lại mã hàng, số lượng, khối lượng khi nhận hàng!</i></td>
                    </tr> 
                    <tr>
                    <td class='no-boder body-text' colspan='3'>Tổng số<i>(bằng chữ)</i></td>
            </tr> 
            <tr>
                      <td class='no-boder body-text' colspan='3'>Chứng từ, giấy tờ kèm theo:</td>
            </tr> 
            <tr>
                      <td class='no-boder body-text r-text' colspan='3' style='padding-right: 60px'><i>Ngày ${moment().date()} tháng ${moment().month()+1} năm ${moment().year()}</i></td>
            </tr> 
            <tr>
                <td class='no-boder body-text m-text' colspan='1'>Thủ kho<br/><i>(Ký, họ tên)</i></td>
                <td class='no-boder body-text m-text' colspan='1'>Lái xe<br/><i>(Ký, họ tên)</i></td>
                <td class='no-boder body-text m-text' colspan='1'>Người nhận hàng<br/><i>(Ký, họ tên)</i></td>
            </tr> 
            <tr>
                      <td class='no-boder body-text m-text' colspan='1'></td>
                      <td class='no-boder body-text m-text' colspan='1'></td>
                      <td class='no-boder body-text m-text' colspan='1'></td>
                  </tr> 
                  <tr>
                    <td class='no-boder body-text m-text' colspan='1'></td>
                    <td class='no-boder body-text m-text' colspan='1'></td>
                    <td class='no-boder body-text m-text' colspan='1'></td>
                  </tr> 
                  <tr>
                    <td class='no-boder body-text m-text' colspan='1'></td>
                    <td class='no-boder body-text m-text' colspan='1'></td>
                    <td class='no-boder body-text m-text' colspan='1'></td>
                </tr> 
              </table>
            </div>
            <script>
                JsBarcode("#code128", "${item.ID}", {format: "code128", fontSize: 14, height: 30, width: 1.5, textMargin: 2, displayValue: true});
            </script>
        </body>
  </html>`;
  if(render) {
    return print_content;
  }
  var mywindow = window.open("", "PRINT");
  mywindow.document.write(print_content);
  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/
  mywindow.print();
  // mywindow.close();
  return true;
}

export function print_export_receipt_list(item, packages, render = false) {
  let html_packages = ''
  for (let i = 0; i < packages.length; i++) {
    const p = packages[i];
    html_packages = html_packages + `<tr>
                        <td class='body-text m-text'>${i+1}</td>
                        <td class='body-text m-text width-idpx'>${p.ID}</td>
                        <td class='body-text m-text quantity'>${currency(p.NumberOfPack,'',0)}</td>
                        <td class='body-text m-text width-volumn'>${currency(p.Weight,'',1)}</td>
                        <td class='body-text m-text width-volumn'>${currency(p.Volume,'',1)}</td>
                        <td class='body-text m-text width-note'>${p.NoteForCustomer ? p.NoteForCustomer : ''}</td>
                      </tr>`
  }

  const print_content = `<html>
  <head>
      <title></title>
      <script src="/static/js/JsBarcode.all.min.js"></script>
      <style>
          .row { display: flex; } .flex { flex: 1 1 auto; } .flex.lg6 { flex-basis: 50%; flex-grow: 0; max-width: 50%; } .flex.lg8 { flex-basis: 66%; flex-grow: 0; max-width: 66%; } .flex.lg4 { flex-basis: 34%; flex-grow: 0; max-width: 34%; } .flex.lg3 { flex-basis: 25%; flex-grow: 0; max-width: 25%; } .flex.lg12 { flex-basis: 100%; flex-grow: 0; max-width: 100%; } .title { font-size: 18px; font-weight: bold; text-align: center; } .sub-title { font-size: 14px; font-weight: bold; } .l-text { text-align: left; } .r-text { text-align: right; } .m-text { text-align: center; } .note-text { font-size: 14px; font-weight: bold; font-style: italic; text-align: center; } .body-text { font-size: 14px; } table { border-collapse: collapse; width: 100%; } td, th { border: 1px solid #4a4a4a; text-align: left; padding: 1px; } hr { border: 0.5px solid #dddddd; } tr, td, th { page-break-inside: avoid !important; height: 30px; padding: 5px} @media print { @page { size: auto; margin: 2mm 2mm 2mm 2mm; } html { background-color: #FFFFFF; margin: 0px; } body { margin: 0mm 0mm 0mm 0mm; } } .no-boder {border: 0px !important;} .quantity {width: 50px;} .width-volumn {width: 80px;} .width-idpx {width: 70px;} .width-note {width: 200px;}
      </style>
  </head>
  
  <body style='width:148.5mm;font-family: Roboto,sans-serif;'>
      <div class='layout wrap'>
          <table>
              <tr>
                <td class='no-boder sub-title m-text'>TEE-LOGISTICS<br/>XNK-VẬN CHUYỂN HÀNG HÓA</td>
                <td class='no-boder sub-title m-text'>PHIẾU GIAO HÀNG</td>
              </tr>	
              <tr>
                <td class='no-boder sub-title m-text'>090.81.82.700 - 090.81.82.700</td>
                <td class='no-boder body-text m-text'><i>Ngày ${moment().date()} tháng ${moment().month()+1} năm ${moment().year()}</i></td>
              </tr>	
              <tr>
                <td class='no-boder sub-title m-text'>Mã khách: ${ item.Username }</td>
                <td class='no-boder body-text m-text'><image src='/static/print-logo.png' width='120px'/></td>
              </tr>
              <tr>        
                <td class='no-boder body-text'>Người nhận hàng: ${item.Receiver}</td>
                <td class='no-boder body-text'>Điện thoại: ${item.Phone}</td>
              </tr>
              <tr>
                <td class='no-boder body-text' colspan='2'>Địa chỉ nhận hàng: ${item.Address}</td>
              </tr>
              <tr>
                <td class='no-boder body-text' colspan='2'>Đơn vị vận chuyển: ${item.Shipper}</td>
              </tr>
            </table>
            <table>
              <tr>
                  <td class='sub-title m-text width-idpx' rowspan='2'>STT</td>            
                  <td class='sub-title m-text width-idpx' rowspan='2'>Mã PX</td>
                  <td class='sub-title m-text quantity' rowspan='2'>Số kiện</td>            
                  <td class='sub-title m-text' colspan='2'>Khối lượng</td>            
                  <td class='sub-title m-text width-note' rowspan='2'>Ghi chú</td>
              </tr>
              <tr>                
                  <td class='sub-title m-text width-volumn'>KG</td>
                  <td class='sub-title m-text width-volumn'>Kích thước</td>
              </tr>
              <tr>
                <td class='sub-title m-text' colspan='2'>Tổng số</td>
                <td class='sub-title m-text quantity'></td>
                <td class='sub-title m-text width-volumn'>${currency(_.sumBy(packages, 'Weight'),'',1)}</td>
                <td class='sub-title m-text width-volumn'>${currency(_.sumBy(packages, 'Volume'),'',1)}</td>
                <td class='sub-title m-text'></td>
              </tr>
              ${html_packages}
              </table>
              <table>
              <tr>
                        <td class='no-boder sub-title' colspan='3'><i>Quý khách vui lòng kiểm tra mã hàng, số lượng, khối lượng và phản hồi lại trong thời gian 2 ngày sau khi nhận hàng !
                        Mọi khiếu nại sau 2 ngày, Việt Taobao sẽ không tiếp nhận và giải quyết!</i></td>
              </tr> 
              <tr>
                        <td class='no-boder body-text' colspan='3'>Tổng số<i>(bằng chữ)</i></td>
              </tr> 
              <tr>
                        <td class='no-boder body-text' colspan='3'>Chứng từ, giấy tờ kèm theo:</td>
              </tr> 
              <tr>
                        <td class='no-boder body-text r-text' colspan='3' style='padding-right: 60px'><i>Ngày ${moment().date()} tháng ${moment().month()+1} năm ${moment().year()}</i></td>
              </tr> 
              <tr>
                  <td class='no-boder body-text m-text' colspan='1'>Thủ kho<br/><i>(Ký, họ tên)</i></td>
                  <td class='no-boder body-text m-text' colspan='1'>Lái xe<br/><i>(Ký, họ tên)</i></td>
                  <td class='no-boder body-text m-text' colspan='1'>Người nhận hàng<br/><i>(Ký, họ tên)</i></td>
              </tr> 
              <tr>
                        <td class='no-boder body-text m-text' colspan='1'></td>
                        <td class='no-boder body-text m-text' colspan='1'></td>
                        <td class='no-boder body-text m-text' colspan='1'></td>
                    </tr> 
                    <tr>
                      <td class='no-boder body-text m-text' colspan='1'></td>
                      <td class='no-boder body-text m-text' colspan='1'></td>
                      <td class='no-boder body-text m-text' colspan='1'></td>
                    </tr> 
                    <tr>
                      <td class='no-boder body-text m-text' colspan='1'></td>
                      <td class='no-boder body-text m-text' colspan='1'></td>
                      <td class='no-boder body-text m-text' colspan='1'></td>
                  </tr> 
                </table>
            </div>
        </body>
  </html>`;
  if(render) {
    return print_content;
  }
  var mywindow = window.open("", "PRINT");
  mywindow.document.write(print_content);
  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/
  mywindow.print();
  // mywindow.close();
  return true;
}