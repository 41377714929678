import shop from '../../api/paymentHistory';
import { sendSuccessNotice, sendErrorNotice, getCurrentTime } from '@/commons/utils';
import Vue from 'vue';

// initial state
const state = {
    loading: false,
    all: {},
    selected: {}
};

// getters
const getters = {};

// actions
const actions = {
    getPaymentHistoryList({ commit }, param) {
        commit("setLoading", { loading: true });
        shop.getPaymentHistoryList(data => {
            commit('setPaymentHistoryList', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getPaymentHistoryDetail({ commit }, order_id) {
        commit("setLoading", { loading: true });
        shop.getPaymentHistoryDetail(data => {
            commit('setPaymentHistoryDetail', data);
            commit("setLoading", { loading: false });
        }, Vue.http, order_id, this.state.authentication.token);
    },
};

// mutations
const mutations = {
    setPaymentHistoryList(state, data) {
        state.all = data;
    },
    setPaymentHistoryDetail(state, item) {
        state.selected = item.data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
