import shop from '../../api/payOrder';
import { sendSuccessNotice, sendErrorNotice, getCurrentTime } from '@/commons/utils';
import Vue from 'vue';

// initial state
const state = {
    loading: false,
    selectedItem: {},
    all: [],
    total: 0
};

// getters
const getters = {};

// actions
const actions = {
    getPayOrderList({ commit }, param) {
        commit('setLoading', {loading: true});
        shop.getPayOrderList(data => {
            commit('setPayOrderList', data);
            commit('setLoading', {loading: false});
        }, Vue.http, param, this.state.authentication.token);
    },
    getPayOrderDetail({ commit }, customerId) {
        commit('setLoading', {loading: true});
        shop.getPayOrderDetail(data => {
            commit('setPayOrderDetail', data);
            commit('setLoading', {loading: false});
        }, Vue.http, customerId, this.state.authentication.token);
    },
    updatePayOrder({ commit }, {order_ids, deposit_ids, cash, method, shipFee, feeOther, feeOtherName, customer_id}) {
        shop.updatePayOrder(data => {
            commit('updatePayOrderList', data);
            sendSuccessNotice('Thanh toán thành công.')
        }, Vue.http, {order_ids, deposit_ids, cash, method, shipFee, feeOther, feeOtherName, customer_id}, this.state.authentication.token);
    },
};

// mutations
const mutations = {
    setPayOrderList(state, res) {
        state.all = res.data;
        state.total = res.total;
    },
    setPayOrderDetail(state, item) {
        state.selectedItem = item.data;
    },
    updatePayOrderList(state, res) {
        
    },
    setLoading(state, { loading }) {
        _.debounce(function () { state.loading = loading; }, 400, {
            'leading': false,
            'trailing': true
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
