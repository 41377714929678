import configs from '@/configs';
import {request_header} from '@/commons/hpod';

export default {
    getOrderTransactionList(cb, $http, params, token) {
        const req = {
            headers: request_header(token)
        };
        if (params.package_type) {
            $http.get(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/order_transactions/?OrderID=${params.order_id}&PackageType=${params.package_type}`, req).then(function (res) {
                setTimeout(() => cb(res.data), 100);
            });
        }
        else {
            $http.get(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/order_transactions/?OrderID=${params.order_id}`, req).then(function (res) {
                setTimeout(() => cb(res.data), 100);
            });
        }
    },
    putOrderTransaction(cb, $http, data, token) {
        const header = {
            headers: request_header(token)
        };
        let req = _.cloneDeep(data);
        $http.put(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/order_transactions/`, req, header).then(function (res) {
            setTimeout(() => cb(res.data), 100);
        });
    },
    deleteOrderTransaction(cb, $http, data, token) {
        const req = {
            body: {Type: data.type, OrderID: data.orderId, TransactionCode: data.transactionCode, OrderCode: data.orderCode},
            headers: request_header(token)
        };
        $http.delete(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/order_transactions/${data.id}`, req).then(function (res) {
            setTimeout(() => cb(res.data), 100);
        });
    },
    postOrderTransaction(cb, $http, data, token) {
        const header = {
            headers: request_header(token)
        };
        let req = _.cloneDeep(data);
        $http.post(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/order_transactions/${data.ID}`, req, header).then(function (res) {
            setTimeout(() => cb(res.data), 100);
        });
    },
    updateMultiple(cb, $http, token, param) {
        const header = {
          headers: request_header(token)
        };
        let req = _.cloneDeep(param);
        Object.keys(req).forEach((key) => (req[key] == null) && delete req[key]);
    
        $http.post(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/order_transactions/`, req, header).then(function (res) {
          setTimeout(() => cb(res.data), 100);
        });
    }
}
