<template>
  <v-navigation-drawer
    id="appDrawer"
    :mini-variant.sync="mini"
    fixed
    :dark="$vuetify.dark"
    app
    v-model="sidebarExpanded"
    width="220"
    >
    <div color="darken-1" class="hpo_color" style="height: 64px">
      <img v-bind:src="computeLogo" height="64" alt="">
    </div>
    <vue-perfect-scrollbar class="drawer-menu--scroll" :settings="scrollSettings">
      <v-list dense expand>
        <template v-for="(item, i) in menus">
          <template v-if="(item.roleId & moduleRole) && ((isDepositOrder && !item.orderSystem) || (!isDepositOrder && !item.depositSystem))">
            <!--group with subitems-->
            <v-list-group v-if="item.items && !item.hideSub" :key="item.name" :group="item.group" :prepend-icon="item.icon" no-action="no-action">
                <v-list-item slot="activator" ripple="ripple">
                  <v-list-item-content>
                    <v-list-item-title>{{ isChinessLanguage && item.cn_title ? item.cn_title : item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <template v-for="(subItem, i) in item.items">
                  <!--sub group-->
                  <v-list-group v-if="subItem.items" :key="subItem.name" :group="subItem.group" sub-group="sub-group">
                    <v-list-item slot="activator" ripple="ripple">
                      <v-list-item-content>
                        <v-list-item-title>{{ isChinessLanguage && subItem.cn_title ? subItem.cn_title : subItem.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-for="(grand, i) in subItem.children" :key="i" :to="genChildTarget(item, grand)" :href="grand.href" ripple="ripple">
                      <v-list-item-content>
                        <v-list-item-title>{{ isChinessLanguage && grand.cn_title ? grand.cn_title : grand.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                  <!--child item-->
                  <v-list-item v-else-if="(!subItem.roleId || (subItem.roleId & reportRole)) && ((isDepositOrder && !subItem.orderSystem) || (!isDepositOrder && !subItem.depositSystem))" :key="i" :to="genChildTarget(item, subItem)" :href="subItem.href" :disabled="subItem.disabled" :target="subItem.target" ripple="ripple">
                    <v-list-item-content>
                      <v-list-item-title><span>{{ isChinessLanguage && subItem.cn_title ? subItem.cn_title : subItem.title }}</span></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="subItem.action">
                      <v-icon :class="[subItem.actionClass || 'success--text']">{{ subItem.action }}</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </template>
            </v-list-group>
            <v-subheader v-else-if="item.header" :key="i">{{ item.header }}</v-subheader>
            <v-divider v-else-if="item.divider" :key="i"></v-divider>
            <!--top-level link-->
            <v-list-item v-else :to="!item.href ? { name: item.name } : null" :href="item.href" ripple="ripple" :disabled="item.disabled" :target="item.target" rel="noopener" :key="item.name">
              <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <v-badge color="orange" :content="getOrderNumber(item.name)" v-if="item.badge && getOrderNumber(item.name)">
                    {{ isChinessLanguage && item.cn_title ? item.cn_title : item.title }}
                  </v-badge>
                  <span v-else>{{ isChinessLanguage && item.cn_title ? item.cn_title : item.title }}</span>
                  </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="item.subAction">
                <v-icon class="success--text">{{ item.subAction }}</v-icon>
              </v-list-item-action>
            </v-list-item>
        </template>
      </template>
      </v-list>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>
<script>
import menu from '@/api/menu';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { mapState } from "vuex";
import configs from "@/configs";;
export default {
  name: 'app-drawer',
  components: {
    VuePerfectScrollbar,
  },
  props: {
    expanded: {
      type: Boolean,
      default: true
    },
  },
  data: () => ({
    mini: false,
    menus: menu,
    isDepositOrder: configs.SYSTEM_TYPE != 'order',
    scrollSettings: {
      maxScrollbarLength: 160,
      wheelPropagation: true
    }    
  }),
  computed: {
    sidebarExpanded: {
      get() {
        return this.$store.state.commons.sidebarExpanded;
      },
      set(val) {
        this.$store.commit("commons/setSidebarExpanded", { expanded: val });
      }
    },
    computeGroupActive () {
      return true;
    },
    computeLogo () {
      return '/static/v-logo.png';
    },
    isChinessLanguage() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreTQ) == configs.ROLE_TYPE.StoreTQ && this.$store.state.authentication.user.ischinesslanguage;
    },
    sideToolbarColor () {
      return this.$vuetify.options.extra.sideNav;
    },
    moduleRole() {
      return this.$store.state.authentication.role.Module;
    },
    reportRole() {
      return this.$store.state.authentication.role.Report;
    },
    ...mapState({
      summaryOrders: state => state.main_order.summaryOrders.data
    })
  },
  created() {
    this.fetchData();
    this.timer = setInterval(this.fetchData, 1000*configs.SYSTEM_CONFIG.notification_time);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    genChildTarget (item, subItem) {
      if (subItem.href) return;
      if (subItem.component) {
        return {
          name: subItem.component,
        };
      }
      return { name: `${item.group}/${(subItem.name)}` };
    },
    fetchData() {
      if(this.$store.state.authentication.token != "") {
        this.$store.dispatch("main_order/getSummaryOrders");
      }
    },
    getOrderNumber(name) {
      if (!this.summaryOrders) {
        return '';
      }
      if(name == 'OrderList') {
        return this.summaryOrders.order
      }
      if(name == 'Deposit') {
        return this.summaryOrders.deposit
      }
      if(name == 'PayServiceList') {
        return this.summaryOrders.payservice
      }
      if(name == 'Deliverable') {
        return this.summaryOrders.package
      }
      if(name == 'hashtag-don-hang') {
        return this.summaryOrders.tag
      }
    }
  }
};
</script>


<style lang="stylus" scoped>
#appDrawer
  overflow: hidden
  .drawer-menu--scroll
    height: calc(100vh - 48px)
    overflow: auto
.v-application 
  a
  .primary--text 
    color: #50565d !important
    caret-color: #50565d !important
.v-badge__badge
  left: 0px !important
</style>
