import user_api from '@/api/user';
import { request_list } from '@/commons/hpod';
import moment from 'moment';
import Vue from 'vue';

const state = {
    date_range: [],
    period_date: [],
    compare_date: false,
    allowCompare: false,
    start_time: undefined,
    end_time: undefined,
    compare_start_time: undefined,
    compare_end_time: undefined,
    rangeSelect: '',
    rangeSelectCompare: '',
    all_users: [],
    stores: [],
    chinaStores: [],
    sidebarExpanded: true,
    orderWidgetSettings: {
        customer: true,
        staff: true,
        product: true,
        chat: true,
        paymentHistory: true,
        paymentArea: true,
        complain: true,
        changeHistory: true,
        transaction: true,
    },
    filters: {
        "warehouse_list": [],
        "customer_list": [],
        "customer_level": [],
        "order_list": [],
        "payment_history_list": [],
        "payorder_list": [],
        "recharge_list": [],
        "withdraw_list": [],
        "transactioncode_list": [],
        "deposit_list": [],
        "complain_list": [],
        "storemanager_list": [],
        "bigpackage_list": [],
        "feeWeight_list": [],
        "feeBuy_list": [],
        "staff_list": [],
        "notification_list": [],
        "historypaywallet": [],
        "deliverable_list": [],
        "accountantorder_list": [],
        "accountantcumulation_list": [],
        "accountantcumulationvnd_list": [],
        "feeVolume_list": [],
        "payservice_list": [],
        "tuikuan_list": [],
        "storereport_list": [],
        "chinastore_list": [],
        "feeCheck_list": [],
        "feeGuarantee_list": [],
        "feeReinforce_list": [],
        "prices_list": [],
        "exportreceipt_list": [],
        "flag_list": [],
        "bao-cao-cong-no": [],
        "bankmessages_list": [],
    },
    filterConditions: {
        "warehouse_list": [],
        "customer_list": [],
        "customer_level": [],
        "order_list": [],
        "payment_history_list": [],
        "payorder_list": [],
        "recharge_list": [],
        "withdraw_list": [],
        "transactioncode_list": [],
        "deposit_list": [],
        "complain_list": [],
        "storemanager_list": [],
        "bigpackage_list": [],
        "feeWeight_list": [],
        "feeBuy_list": [],
        "staff_list": [],
        "notification_list": [],
        "historypaywallet": [],
        "deliverable_list": [],
        "accountantorder_list": [],
        "accountantcumulation_list": [],
        "accountantcumulationvnd_list": [],
        "feeVolume_list": [],
        "payservice_list": [],
        "tuikuan_list": [],
        "storereport_list": [],
        "chinastore_list": [],
        "feeCheck_list": [],
        "feeGuarantee_list": [],
        "feeReinforce_list": [],
        "prices_list": [],
        "exportreceipt_list": [],
        "flag_list": [],
        "bao-cao-cong-no": [],
        "bankmessages_list": [],
    }
};

const getters = {};

const actions = {
    getAllUsers({ commit }) {
        user_api.getAllUsers(res => {
            commit('setAllUsers', res);
        }, Vue.http, this.state.authentication.token);
    },
    getAllStores({ commit }) {
        request_list('stores', data => {
            commit('setListStores', data);
        }, Vue.http, {}, this.state.authentication.token);
    },
    getAllChinaStores({ commit }) {
        request_list('chinastores', data => {
            commit('setListChinaStores', data);
        }, Vue.http, {}, this.state.authentication.token);
    },
    setDateRange({commit }, date_range_val) {
        commit('setDates', date_range_val);
    },
    setPeriodDates({commit }, date_range_val) {
        commit('setPeriodDates', date_range_val);
    },
    setCompareDate({commit }, compare_date_val) {
        commit('setCompareDate', compare_date_val);
    },
    setRangeSelect({commit }, rangeSelectVal) {
        commit('setRangeSelect', rangeSelectVal);
    },
    setRangeSelectCompare({commit }, rangeSelectCompareVal) {
        commit('setRangeSelectCompare', rangeSelectCompareVal);
    },
    setOrderWidgets({commit }, { widgets }) {
        commit('setOrderWidgets', { widgets });
    },
};

const mutations = {
    setAllUsers(state, res) {
        state.all_users = res.data || [];
    },
    setListStores(state, res) {
        state.stores = res.data || [];
    },
    setListChinaStores(state, res) {
        state.chinaStores = res.data || [];
    },
    setOrderWidgets(state, { widgets }) {
        state.orderWidgets = widgets;
    },
    setSidebarExpanded(state, { expanded }) {
        state.sidebarExpanded = expanded;
    },
    setFilters(state, { filter }) {
        state.filters = filter;
    },
    setFilterConditions(state, { filter }){
        state.filterConditions = filter;
    },
    setDates(state, date_range_val) {
        state.start_time = moment(date_range_val[0]).startOf('d').add(60 * moment().utcOffset(), 'seconds').unix();
        state.end_time = moment(date_range_val[1]).endOf('d').add(60 * moment().endOf('d').utcOffset(), 'seconds').unix();

        state.date_range = date_range_val;
    },
    setPeriodDates(state, val) {
        state.compare_start_time = moment(val[0]).startOf('d').add(60 * moment().utcOffset(), 'seconds').unix();
        state.compare_end_time = moment(val[1]).endOf('d').add(60 * moment().endOf('d').utcOffset(), 'seconds').unix();

        state.period_date = val;
    },
    setCompareDate(state, compare_date_val) {
        state.compare_date = compare_date_val;
        if(!compare_date_val){
            state.period_date = [];
            state.compare_start_time = null;
            state.compare_end_time = null;
        }
    },
    setRangeSelect(state, rangeSelectVal) {
        state.rangeSelect = rangeSelectVal;
    },
    setRangeSelectCompare(state, rangeSelectCompareVal) {
        state.rangeSelectCompare = rangeSelectCompareVal;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
