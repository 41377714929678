import { request_list, update_detail, create_detail } from '@/commons/hpod';
import { sendSuccessNotice, sendErrorNotice } from '@/commons/utils';
import Vue from 'vue';
const viewTitle = 'dashboard'
// initial state
const state = {
    loading: false,
    advancePayment: {
        'Total': 0,
        "Detail": {}
    },
    walletSummary: {
        'Total': 0,
        'TotalWithdraw': 0,
        'TotalRecharge': 0
    }
};

// getters
const getters = {};

// actions
const actions = {
    getAdvancePayment({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list('dashboard/advancepayment', data => {
            commit('setAdvancePayment', data.data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getWalletSummary({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list('dashboard/totalwallet', data => {
            commit('setWalletSummary', data.data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    }
};

// mutations
const mutations = {
    setAdvancePayment(state, data) {
        state.advancePayment = data;
    },
    setWalletSummary(state, data) {
        state.walletSummary = data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
