import Crud from './crud'
import { update_detail } from '@/commons/hpod';
import { sendSuccessNotice, sendErrorNotice, refreshResource } from '@/commons/utils';
import Vue from 'vue';
var crud = new Crud('prices', 'bảng giá vận chuyển');

const state = {
    ...crud.state,
    selected: {
        ID: 0,
        Name: '',
        Status: 0,
        Global: 0
    }
};
const getters = {
    ...crud.getters,
};
const actions = {
    ...crud.actions,
    duplicateDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        update_detail(`${crud.endpoint}/duplicate`, res => {
            if (res.data.status === false) {
                sendErrorNotice(`Không copy ${crud.viewTitle}.`)
            }
            else {
                sendSuccessNotice(`Đã copy ${crud.viewTitle} thành công.`)
                refreshResource();
            }
            commit("setLoading", { loading: false });
        }, Vue.http, {}, id, this.state.authentication.token);
    }
};
const mutations = {
    ...crud.mutations,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};