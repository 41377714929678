import moment from "moment"

  export function sendSuccessNotice (notice) {
    window.getApp.$emit('APP_RESOURCE_UPDATED', notice);
  }
  
  export function sendErrorNotice (notice) {
    window.getApp.$emit('APP_RESOURCE_FAILED', notice);
  }

  export function refreshResource () {
    window.getApp.$emit('REQUEST_REFRESH_DATA');
  }

  export function buildBreadcrumb(routeName, routePath, pId, title, menu, paths, isChinessLanguage) {
    let breadcrumbs = [];
    menu.forEach(item => {
      if (item.name === routeName) {
        breadcrumbs.push({
          text: isChinessLanguage && item.cn_title ? item.cn_title : item.title,
          link: false,
          href: ""
        });
      } else if (item.items) {
        breadcrumbs = _.concat(breadcrumbs, buildBreadcrumb(routeName, routePath, pId, title, item.items, paths))
      } else if (item.children) {
        let child = item.children.find(i => {
          return i.component === routeName;
        });
        if (child) {
          let path = _.find(paths, { name: item.name });
          breadcrumbs.push({
            text: isChinessLanguage && item.cn_title ? item.cn_title : item.title,
            disabled: false,
            href: path ? path.path : ""
          });
          breadcrumbs.push({
            text: title || `${isChinessLanguage && child.cn_title ? child.cn_title : child.title} (#${pId})`,
            link: false,
            href: ""
          });
        }
      }
    });
    return breadcrumbs;
  }

  export function getCurrentTime(subSeconds = 0) {
    var d = new Date();
    d.setTime( d.getTime());
    return moment(d).subtract(subSeconds, 'seconds');
  }

  export function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 export function isMobileAgent() {
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}

export function focusNextElement(e) {
  const inputs = Array.from(e.target.ownerDocument.querySelectorAll('.input-group--focused input:not([type=hidden])'));
  const index = inputs.indexOf(e.target);
  if (index < inputs.length) {
    inputs[index + 1].focus();
  }
}

export function playSound (sound) {
  if(sound) {
    var audio = new Audio(sound);
    audio.play();
  }
}