import Crud from './crud'
var crud = new Crud('feereinforce', 'phí gia cố');

const state = {
    ...crud.state,
    selected: {
        ID: 0,
        VolumeMin: 0,
        VolumeMax: 0,
        Amount: 0
    }
};
const getters = {
    ...crud.getters,
};
const actions = {
    ...crud.actions,
};
const mutations = {
    ...crud.mutations,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};