import configs from '@/configs';
import {request_header, request_list} from '@/commons/hpod';

export default {
    getPayOrderList(cb, $http, param, token) {
        request_list('pay_order', cb, $http, param, token)
    },
    getPayOrderDetail(cb, $http, customerId, token) {
        const req = {
            headers: request_header(token)
        };
        $http.get(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/pay_order/${customerId}`, req).then(function (res) {
            setTimeout(() => cb(res.data), 100);
        });
    },
    updatePayOrder(cb, $http, {order_ids, deposit_ids, cash, method, shipFee, feeOther, feeOtherName, customer_id}, token) {
        const header = {
            headers: request_header(token)
        };
        let req = _.cloneDeep({order_ids: order_ids.join(','), deposit_ids: deposit_ids.join(','), cash, method, shipFee, feeOther, feeOtherName, customer_id});
        $http.post(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/pay_order/${customer_id}`, req, header).then(function (res) {
            setTimeout(() => cb(res.data), 100);
        });
    }
}
