import Crud from './crud'
var crud = new Crud('feecheck', 'phí kiểm đếm');

const state = {
    ...crud.state,
    selected: {
        ID: 0,
        QuantityMin: 0,
        QuantityMax: 0,
        PackageA: 0,
        PackageB: 0
    }
};
const getters = {
    ...crud.getters,
};
const actions = {
    ...crud.actions,
};
const mutations = {
    ...crud.mutations,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};