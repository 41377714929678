import Crud from './crud'
var crud = new Crud('feeguarantee', 'phí bảo hiểm');

const state = {
    ...crud.state,
    selected: {
        ID: 0,
        OrderType: 0,
        PackageName: '',
        PercentFee: 0
    }
};
const getters = {
    ...crud.getters,
};
const actions = {
    ...crud.actions,
};
const mutations = {
    ...crud.mutations,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};