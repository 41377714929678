import { request_list, update_detail, export_list } from '@/commons/hpod';
import { sendSuccessNotice, sendErrorNotice } from '@/commons/utils';
var FileSaver = require('file-saver');
import Vue from 'vue';
const endpoint = 'accountantorder'
const viewTitle = 'Kiểm soát chênh lệch đơn hàng'

// initial state
const state = {
    loading: false,
    all: {
        data: [],
        total: 0,
        summary: {}
    }
};

// getters
const getters = {};

// actions
const actions = {
    getList({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list(endpoint, data => {
            commit('setList', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    setDetail({ dispatch }, {data, id, param}) {
        update_detail(endpoint, res => {
            if (res.data.status === 'fail') {
                sendErrorNotice(`Không thể cập nhật ${viewTitle}.`)
            }
            else {
                dispatch("getList", param);
                sendSuccessNotice(`Cập nhật ${viewTitle} thành công.`)
            }
        }, Vue.http, data, id, this.state.authentication.token);
    },
    export({ commit }, param) {
        commit("setLoading", { loading: true });
        export_list(endpoint, res => {
            commit("setLoading", { loading: false });
            var blob = new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"});
            FileSaver.saveAs(blob, `Order Export_${new Date().getTime()}.xlsx`);
        }, Vue.http, param, this.state.authentication.token);
    },
};

// mutations
const mutations = {
    setList(state, data) {
        state.all = data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
