import shop from '../../api/orderComment';
import { sendSuccessNotice, sendErrorNotice, getCurrentTime } from '@/commons/utils';
import Vue from 'vue';

// initial state
const state = {
    loading: false,
    all: {},
    selected: {}
};

// getters
const getters = {};

// actions
const actions = {
    getOrderCommentList({ commit }, param) {
        shop.getOrderCommentList(data => {
            commit('setOrderCommentList', data);
        }, Vue.http, param, this.state.authentication.token);
    },
    putOrderComment({ dispatch }, param) {
        shop.putOrderComment(data => {
            dispatch('getOrderCommentList', {id: param.OrderID, type: param.OrderType});
        }, Vue.http, param, this.state.authentication.token);
    },
};

// mutations
const mutations = {
    setOrderCommentList(state, res) {
        state.all = res.data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
