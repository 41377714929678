import { sendSuccessNotice, sendErrorNotice } from '@/commons/utils';
import { request_detail, request_list, update_detail, request_header, remove_detail, create_detail } from '@/commons/hpod';
import Vue from 'vue';
const endpoint = 'deposit'
import configs from '@/configs'
// initial state
const state = {
    loading: false,
    all: {},
    selected: {},
};

// getters
const getters = {};

// actions
const actions = {
    getList({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list(endpoint, data => {
            commit('setList', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    getDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        request_detail(endpoint, data => {
            commit('setDetail', data);
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    removeDetail({ commit }, id) {
        commit("setLoading", { loading: true });
        remove_detail(endpoint, res => {
            if (res.data.status === false) {
                sendErrorNotice('Không thể xóa đơn hàng ký gửi.')
            }
            else {
                sendSuccessNotice('Đã xóa đơn hàng ký gửi thành công.')
            }
            commit("setLoading", { loading: false });
        }, Vue.http, id, this.state.authentication.token);
    },
    setDetail({ commit, dispatch }, {data, params}) {
        if(data.OrderID > 0) {
            delete data.CreatedDate;
            delete data.CreatedBy;
            data.ID = data.OrderID;
            delete data.OrderID;
            data.ModifiedBy = this.state.authentication.user.username;
            update_detail(endpoint, res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice('Không thể cập nhật đơn hàng ký gửi.')
                }
                else {
                    sendSuccessNotice('Cập nhật đơn hàng ký gửi thành công.')
                    dispatch("getDetail", data.ID);
                }
            }, Vue.http, data, data.ID, this.state.authentication.token);
        }
        else {
            delete data.CreatedDate;
            delete data.CreatedBy;            
            delete data.ID;            
            delete data.OrderID;
            data.CreatedBy = this.state.authentication.user.username;
            create_detail(endpoint, res => {
                if (res.data.status === 'fail') {
                    sendErrorNotice('Không thể tạo mới đơn hàng ký gửi.')
                }
                else {
                    sendSuccessNotice('Tạo mới đơn hàng ký gửi thành công.')
                    if(params){
                        dispatch("getList", params);
                    }
                }
            }, Vue.http, data, this.state.authentication.token);
        }
        
    },
    updateMultiple({ commit }, param) {
        const header = {
            headers: request_header(this.state.authentication.token)
        };
        var req = {
          'OrderIDs': param.OrderIDs,
          'Deliverable': param.Deliverable
        }
        Vue.http.post(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/deposit/`, req, header).then(function (res) {
          dispatch('getListOrders', param);
        });
    },
};

// mutations
const mutations = {
    setList(state, data) {
        state.all = data;
    },
    setDetail(state, item) {
        state.selected = item.data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
