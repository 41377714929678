<template>
    <div class="widget-block clearfix" ref="widget">
        <div class="header-widget clearfix">
            <h2 class="title-widget fl">{{widgetTitle}}</h2>
            <div class="right-action fr">
            <a href="#zoomChart" data-toggle="modal" @click="zoomChart">
                <i class="ico-zoom"></i>
            </a>
            <a href="javascript:void(0)" @click="hideWidget">
                <i class="ico-close"></i>
            </a>
            </div>
        </div>
        <div class="content-widget">
            <slot name="widget-content"></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'widget-temp',
    props:{
        widgetName:{
            type: String,
            default: 'widget-temp'
        },
        widgetTitle:{
            type: String,
            default: 'Default Title'
        },
        widgetId:{
            type: Number,
            default: -1
        }
    },
    methods: {
        zoomChart(){
            this.$root.$emit("zoomChart", {widgetName: this.widgetName, widgetTitle: this.widgetTitle, widgetId: this.widgetId});
        },
        hideWidget(){
            this.$root.$emit("hideWidget", {widgetId: this.widgetId})
        }
    },
}
</script>