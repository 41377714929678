import { sendSuccessNotice, sendErrorNotice } from '@/commons/utils';
import { request_list, update_detail } from '@/commons/hpod';
import Vue from 'vue';
const endpoint = 'deliverable'
// initial state
const state = {
    loading: false,
    all: {}
};

// getters
const getters = {};

// actions
const actions = {
    getList({ commit }, param) {
        commit("setLoading", { loading: true });
        request_list(endpoint, data => {
            commit('setList', data);
            commit("setLoading", { loading: false });
        }, Vue.http, param, this.state.authentication.token);
    },
    setDetail({ commit, dispatch }, {param, data} ) {
        commit("setLoading", { loading: true });
        update_detail(endpoint, res => {
            if (res.data.status === 'fail') {
                sendErrorNotice('Không thể cập nhật mã bill.')
            }
            else {
                sendSuccessNotice('Cập nhật mã bill thành công.')
                dispatch("getList", param);
            }
            commit("setLoading", { loading: false });
        }, Vue.http, data, data.ID, this.state.authentication.token);
    },
};

// mutations
const mutations = {
    setList(state, data) {
        state.all = data;
    },
    setLoading(state, { loading }) {
        state.loading = loading;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
