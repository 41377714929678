import Crud from './crud'
var crud = new Crud('chinastores', 'kho Trung Quốc');

const state = {
    ...crud.state,
    selected: {
        ID: 0,
        Name: '',
        Receiver: '',
        Phone: '',
        Address: ''
    }
};
const getters = {
    ...crud.getters,
};
const actions = {
    ...crud.actions,
};
const mutations = {
    ...crud.mutations,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
